/**
 * Get the value of a given cookie
 * @param name: name of the cookie you need the value of
 */
function readCookie(name) {
    let nameEQ = encodeURIComponent(name) + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
}

var cart = [];
var localStorageData = JSON.parse(localStorage.getItem('orderAndDownloadCart'));

if (localStorageData !== null) {
    cart = JSON.parse(localStorage.getItem('orderAndDownloadCart'));
    $('.cart-count').html(cart.length);
    for (var i = 0; i < cart.length; i++) {
        let cartList = $('.grid-cart.dropdown').find('#cart');
        let id = cart[i].id;
        let name = cart[i].name;
        let amount = cart[i].amount;
        cartList.append('<li class="cart-article" id="' + id + '"><div class="article"><span class="unit">' + amount + ' x </span>' + name + '<div class="article-remove icon-circle-cross "></div></div></li>');
        let t = cartList.find('#' + id);
        $(t).find('.article-remove').attr('data-remove-id', id);
        $(t).find('.article-remove').click(function () {
            let thisRemoveId = $(this).data('remove-id');
            removeFromCart(thisRemoveId);
            $('.article[data-id="' + thisRemoveId + '"]').find('.article-selection-wrapper').hide();
        });
    }
}

/* returns int >=0 if cart contains element with given id */
function cartContains(id) {
    for (var i = 0; i < cart.length; i++) {
        if (cart[i].id === id) {
            return i;
        }
    }
    return -1;
}

var cartItemTempalte = $('#cart-item-template').detach(); // TODO clone

var articles = $('#order_and_download').find('.article');
for (var i = 0; i < articles.length; i++) {
    let id = $(articles[i]).data('id');
    if (cartContains(id) >= 0) {
        let amount = cart[cartContains(id)].amount;
        $(articles[i]).find('.article-selection-wrapper').find('.article-selection').find('span').html(amount);
        $(articles[i]).find('.article-selection-wrapper').find('.article-selection').show();
    }
    $(articles[i]).find('.article-selection-wrapper').find('.article-selection').find('a').click(function () {
        //let id = $(this).parent().parent().parent().parent().data('id');
        let id = $(this).data('id');
        removeFromCart(id);
        $(this).parent().parent().hide();
    });

    $(articles[i]).find('button').click(function () {
        let amount = $(this).parent().find('select').val();
        let name = $(this).data('display-name');
        let id = $(this).data('name');
        let uid = $(this).data('uid');
        removeFromCart(id);
        cart.push({
            "name": name,
            "amount": amount,
            "id": id,
            "uid": uid
        });
        saveLocalStorage(cart);
        $(this).parent().parent().find('.article-selection-wrapper').find('.article-selection').find('span').html(amount);
        $(this).parent().parent().find('.article-selection-wrapper').find('.article-selection').show();

        let cartList = $('.grid-cart.dropdown').find('#cart');
        cartList.append('<li class="cart-article" id="' + id + '"><div class="article"><span class="unit">' + amount + ' x </span>' + name + '<div class="article-remove icon-circle-cross "></div></div></li>');
        let t = cartList.find("#" + id);
        $(t).find('.article-remove').attr('data-remove-id', id);
        $(t).find('.article-remove').click(function () {
            let thisRemoveId = $(this).data('remove-id');
            console.log("! remove from cart " + thisRemoveId)
            removeFromCart(thisRemoveId);
            $('.article[data-id="' + thisRemoveId + '"]').find('.article-selection-wrapper').hide();
        });
        $('.cart-count').html(cart.length);
        $('.grid-cart.dropdown').show();
    });

}

function saveLocalStorage(cart) {
    localStorage.setItem('orderAndDownloadCart', JSON.stringify(cart));
}

function removeFromCart(id) {
    for (var i = 0; i < cart.length; i++) {
        if (cart[i].id === id) {
            cart.splice(i, 1);
            $('.grid-cart.dropdown').find('.dropdown-menu').find('#' + id).remove();
        }
    }
    saveLocalStorage(cart);
    $('.cart-count').html(cart.length);
}

$('.grid-cart').click(function () {
    // open cart
    $('ul#cart').toggle();
});



// order.js

(function () {
    "use strict";

    $(function () {
        //------------------------
        // Check-Order input form
        //------------------------
        function handleOrderItems() {
            var articles = localStorage.getItem('orderAndDownloadCart');
            var articlesList = JSON.parse(articles);

            var orderText = "";

            for (var i = 0; i < articlesList.length; i++) {
                orderText += articlesList[i].amount + ' x ' + articlesList[i].name + ' (' + articlesList[i].uid + ')';
                if (articlesList.length > 1 && i < articlesList.length - 1) {
                    orderText += ", ";
                }
                orderText += "\n";
            }
            //orderText+= "\n---\n\n";
            return orderText;

        }

        function initArticlesInForm() {
            var field = $('textarea[name="articles"]');

            var form = $('.mwf-form');
            if (field.length > 0 && form.length > 0 && localStorage.getItem('orderAndDownloadCart') != undefined) {
                $('#change-items a').appendTo($('textarea[name="articles"]').parent());
                var itemsText = handleOrderItems();
                $(field).val(itemsText);

                // Function for clearing the cookie
                $('a[data-mwf-submit]').filter(function () {
                    return $(this).data('mwf-submit').type === 'next';
                }).on("click", function () {
                    localStorage.removeItem('orderAndDownloadCart');
                    jQuery(document).ajaxComplete(function () {
                        // Function for clearing the cookie
                        $('a[data-mwf-submit]').filter(function () {
                            return $(this).data('mwf-submit').type === 'finish';
                        }).on("click", function () {
                            localStorage.removeItem('orderAndDownloadCart');
                        });
                    });
                });
            }
        }

        if ($('.mwf-form').length) {
            if ($('textarea[name="articles"]').length) {
                initArticlesInForm();
            } else {
                MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

                var observer = new MutationObserver(function(mutations, observer) {
                    initArticlesInForm();
                });

                observer.observe(document.querySelector(".mwf-form"), {
                    subtree: true,
                    childList: true
                });
            }
        }
    }); //DOCUMENT READY
})();
