export default function initFlyout() {
    const _ = [     //trustedOrigins -> not used
        'http://localhost:9000',
        'http://wilo-com.wilo.hq.arithnea.de',
        'https://wilo-com.wilo-websites.com',
        'https://wilo.com'
    ];

    document.addEventListener("DOMContentLoaded", event => {
        const flyout = document.querySelector('.flyout');
        const SCROLLDELAY = 15;

        function readListCounts() {
            // console.log(event.origin);
            //if (trustedOrigins.indexOf(event.origin) == -1) return;

            Object.keys(localStorage)
                .filter(key => key.indexOf('listCount') !== -1)
                .forEach(key => {
                    const split = key.split("_");
                    if (split[0] == wiloconfig.amirada.country) {
                        const selector = '[data-flyout="' + split[2] + '"]'; 
                        const el = document.querySelector(selector);
                        if (!!el && !!localStorage[key]) {
                            el.setAttribute("data-badge", localStorage[key]);
                        }
                    }
                });
        }
        
        function windowNotScrollable() {
            return document.querySelector('html').scrollHeight == document.querySelector('html').clientHeight;
        }
            
        function debounce(fn, delay) {
            clearTimeout(fn._tId);
            fn._tId = window.setTimeout(fn, delay);
        }

        if (!!flyout) {
            window.addEventListener("scroll", event => {
                //if (trustedOrigins.indexOf(event.origin) == -1) return;
            
                debounce(() => {
                    if (flyout.getAttribute('data-visible-forced') != "true") {
                        flyout.setAttribute('data-visible', window.pageYOffset > SCROLLDELAY);
                    }
                }, 50);
            });

            window.addEventListener('resize', event => {
                //if (trustedOrigins.indexOf(event.origin) == -1) return;

                debounce(() => {
                    if (windowNotScrollable()) {
                        flyout.setAttribute('data-visible-forced', true);
                        flyout.setAttribute('data-visible', true);
                    } else {
                        flyout.setAttribute('data-visible-forced', false);
                        flyout.setAttribute('data-visible', window.pageYOffset > SCROLLDELAY);
                    }
                }, 50);
            });
    
            window.addEventListener("listCountChanged", readListCounts);
            
            flyout.querySelector('button').addEventListener('click', event => {
                event.preventDefault();
                flyout.setAttribute('data-visible-forced', true);
                flyout.setAttribute('data-visible', flyout.getAttribute('data-visible') != "true");
            });

            readListCounts();

            if (windowNotScrollable()) {
                flyout.setAttribute('data-visible-forced', true);
                flyout.setAttribute('data-visible', true);
            } else {
                flyout.setAttribute('data-visible', window.pageYOffset > SCROLLDELAY);
            }
        }        
    });
};