import {getCookie} from "./general/cookies"
import "slick-carousel"
import "magnific-popup"
import "bootstrap-sass"
import "featherlight"
import {initThreeColumnGalleries} from "./threeColumnGallery"

import initFlyout from "./flyout"
import { initTextOnImage } from "./modules/textOnImage"
import initEcolutionCountdown from "./modules/ecolutionCountdown"
import initEcolutionAccordion from "./modules/ecolutionAccordion"
import { initEcolutionForm } from "./modules/ecolutionForm"
import initBSRPumpReplacementTeaser from "./modules/bsrPumpReplacementTeaser"
import initBSRFaultSignalAssistance from "./modules/bsrFaultSignalAssistance"
import initBsrAnchorNavigation from "./modules/bsrAnchorNavigation"
import initBsrSubNavigation from "./modules/bsrSubNavigation"
import initBsrMainNavigation from "./modules/bsrMainNavigation"
import initLoginButton from "./loginButton"

/* ==============
  SLICK - START
================ */

// Slick
var nextArrow =
  '<div class="slider-arrow arrow-right"><span class="wilo-icon icon-arrow-right"></span></div>';
var prevArrow =
  '<div class="slider-arrow arrow-left"><span class="wilo-icon icon-arrow-left"></span></div>';

var slickConfigs = {
  downloads: {
    mobileFirst: true,
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  },
  alphabetLetters: {
    slidesToShow: 10,
    swipeToSlide: true,
    nextArrow: nextArrow,
    prevArrow: prevArrow
  },
  slickNav: {
    arrows: true,
    slidesToShow: 3,
    centerPadding: '0px',
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          infinite: true,
          centerMode: true,
          variableWidth: true,
          slidesToShow: 3,
          vertical: false,
          verticalSwiping: false,
          swipeToSlide: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          // centerPadding: '40px',
          slidesToShow: 3,
          vertical: true,
          verticalSwiping: true,
          swipeToSlide: true
        }
      }
    ]
  },
  itemSlider: {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  },
  webShopItemSlider: {
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  },
  serviceSlider: {
    centerMode: true,
    slidesToShow: 1
  },
  contentSlider: {
    slidesToShow: 1,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    infinite: true,
    swipeToSlide: true,
    mobileFirst: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  },
  bsrContentSlider: {
    slidesToShow: 1,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    dots: true,
    infinite: true,
    swipeToSlide: true,
    mobileFirst: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  }
};

function responsiveSlickSlider(selector, configObj, viewport) {
  if ($(selector + ':visible').length > 0) {
    if (window.matchMedia('(' + viewport + ')').matches) {
      $(selector + '.slick-initialized').slick('unslick');
    } else {
      $(selector)
        .not('.slick-initialized')
        .slick(configObj);
      }
  }
}

$(document).ready(function(){
  $('.contentSlider').slick(slickConfigs.contentSlider);
  $('.bsrContentSlider').slick(slickConfigs.bsrContentSlider);
  $('.bsrContentSlider').on('breakpoint', (event) => {
    const innerFAQs = $(this).find('.faq-block .faq-question');
    initFaqToggles(innerFAQs);
  }
);
});

/* ==============
  SLICK - END
================ */

/* ==============
  Resizing - START
================ */

// Returns the current Viewport
function getViewport() {
  return window
    .getComputedStyle(document.querySelector('body'), ':before')
    .getPropertyValue('content')
    .replace(/["']/g, '');
  // need to reset quotes from the :before Value
}

// Values are set in _general.scss and listen on the defined breakpoint-ranges from _wilo-variables.scss
function isViewport(viewport) {
  return viewport === getViewport();
}

// Smooth Scroll
export function scrollSmooth(anker) {
  var currPosition = window.pageYOffset;
  var stepSize = (anker - currPosition) / 80;
  console.log(stepSize);
  var int = setInterval(function() {
    if (stepSize > 0 && anker >= currPosition) {
      currPosition += stepSize;
      window.scrollTo(0, currPosition);
    } else if (stepSize < 0 && anker <= currPosition) {
      currPosition += stepSize;
      window.scrollTo(0, currPosition);
    } else {
      clearInterval(int);
    }
  }, 2);
}

// Will be executed on every resize
function generalResize() {
  adjustHeight($('.download-section'), $('.download-item'));
  responsiveSlickSlider('.toolbar', slickConfigs.toolbar, 'min-width: 992px');
  responsiveSlickSlider(
    '.download-items-wrapper',
    slickConfigs.downloads,
    'min-width: 992px'
  );
  responsiveSlickSlider(
    '.alphabet-letters',
    slickConfigs.alphabetLetters,
    'min-width: 769px'
  );
  scrollTable();
  // Slick Fix
  $('.slick-initialized').each(function() {
    $(this).slick('slickGoTo', 0);
  });
}

// Fix Tables on Resize
function scrollTable() {
  $('.table-module').each(function() {
    if (
      $(this)
        .find('table')
        .width() >
      $(this)
        .parent()
        .width()
    ) {
      $(this).css('overflow', 'auto');
    } else {
      $(this).css('overflow', 'visivle');
    }
  });
}

/* ==============
    Resizing - END
================ */

/* ==============
    General functions - START
================ */

function adjustHeight($wrapper, $element) {
  if (!($wrapper.length === 0 && $element.length === 0)) {
    var $elements = $wrapper.find($element);
    var maxHeight = 0;
    $elements.each(function() {
      $(this).css('height', 'auto');
      if ($(this).outerHeight() > maxHeight) {
        maxHeight = $(this).outerHeight();
      }
    });
    $elements.css('height', maxHeight + 'px');
  }
}

export function ifExists(elem) {
  return elem.length > 0;
}

// Cookie request
function checkCookiesAccepted() {
  if (document.cookie.indexOf('cookies=true') == -1) {
    jQuery('.cookie-box').show();
  }
}

export function createCookie(name, value, days) {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  } else {
    expires = '';
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

export function closeOffCanvasLayer() {
  var offCanvasContainer = document.getElementById('offcanvas');
  var offCanvasAlert = document.getElementById('off-canvas-alert');
  var offCanvasLabel = document.getElementById('off-canvas-alert-content');
  offCanvasContainer.style.display = 'none';
  offCanvasAlert.style.display = 'none';
  offCanvasLabel.innerText = '';
}

/* ==============
    General functions - END
================ */

/* ==============
    Init Functions  - START
================ */

// General initialization methode
function generalInit() {
  initFaqToggles($('.faq-block .faq-question'));
  initFormPageToggles();
  initFormPageTogglesCustomer();
  initDisableNewsletter();
  initThreeColumnGalleries(); 
}

// Faq-toggles
function initFaqToggles(elem) {
  if (ifExists(elem)) {
    elem.on('click', function() {
      $(this)
        .prev('.toggle-icon')
        .toggleClass('rotate-half');
      $(this)
        .next('.faq-answer')
        .stop()
        .slideToggle();
      $(this)
        .parent()
        .toggleClass('open');
      // Refresh slick, WILOOP-8197
      $(this).next('.faq-answer').find('.slick-slider').each(function() {
        $(this).slick('refresh');
      });
    });
  }
}

//Promotab-Webforms
function initPromotabWebforms() {
  document.querySelectorAll(".mwf-form").forEach(function (form) {
	const script =
	  form.children[0] && form.children[0].children[0]
		? form.children[0].children[0].innerHTML
		: null;

	if (script) {
	  new Function(form.children[0].children[0].innerHTML)();
	}
  });

}

window.addEventListener("onRouteChange", function () {
  initFaqToggles();
  initPromotabWebforms();
});

// Formpage-toggles
function initFormPageToggles() {
  var elem = $('.formpage-module .toggle-trigger');
  if (ifExists(elem)) {
    elem.click(function() {
      $(this)
        .prev('.toggle-icon')
        .toggleClass('rotate-half');
      $(this)
        .parents('p')
        .nextAll('.toggle-content')
        .stop()
        .slideToggle();
    });
  }
}

// Registration customer-id toggle
function initFormPageTogglesCustomer() {
  var elem = $("input[name='customer-id']");
  if (ifExists(elem)) {
    elem.change(function() {
      if ($(this).val() == 'show') {
        $('#customer-id').show();
      } else {
        $('#customer-id').hide();
      }
    });
  }
}

// Disable newsletter submitbutton
function initDisableNewsletter() {
  var elem = $('.newsletter-subscribe');
  if (ifExists(elem)) {
    elem.click(function() {
      if ($(this).is(':not(:checked)')) {
        elem.prop('disabled', 'disabled');
      } else elem.prop('disabled', '');
    });
  }
}

/* ==============
    Init Functions  - End
================ */

/* ==============
    Document Ready - START
================ */

$(document).ready(function() {
  /* ==============
        INIT - START
    ================ */
  generalInit();

  /* ==============
        INIT - END
    ================ */

  // Cookie Box
  checkCookiesAccepted();

  $('#cookie-button').on('click', function() {
    createCookie('cookies', 'true', '365');
    $('.cookie-box').fadeOut();
  });

  // Toggle tooltips
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });

  $('a.toggle-configurator').click(function(evt) {
    var that = this;
    $.featherlight(
      '<img src="' +
        window.wiloconfig.distResources +
        'img/mockup-yono.jpg" />',
      {
        beforeClose: function() {
          if (
            confirm(
              'Do you really want to exit the configurator? Unsaved changes will be discarded.'
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    );
  });

  $('.service-slider').slick(slickConfigs.serviceSlider);
  $('.slick-nav div ul').slick(slickConfigs.slickNav);
  $('.item-slider').slick(slickConfigs.itemSlider);
  $('.webshop-item-slider').slick(slickConfigs.webShopItemSlider);

  //script for updating product-counter
  $('.product-counter').html($('.product-control.remove').length);
  $('.product-control').click(function() {
    setTimeout(function() {
      $('.product-counter').html($('.product-control.remove').length);
    }, 10);
  });

  /* ==============
        Conent Expandables - START
    ================ */

  $('.expandable-content-list-module-toggle').click(function() {
    $(this)
      .find('span')
      .toggleClass('rotate-half');
    $(this)
      .parent()
      .find('.expandable-content-list-module-content')
      .stop()
      .slideToggle();
  });

  /* ==============
        Conent Expandables - End
    ================ */

  /* ==============
    Collapse Container - START
================ */

  /* ==============
        Collapse Container - End
    ================ */

  /* ==============
        Dashboard Tabs - START
    ================ */

  $('.tab-headline-wrapper').click(function() {
    if (isViewport('small') || isViewport('medium')) {
      $(this)
        .next('.dashboard-tab-content-wrapper')
        .stop()
        .slideToggle();
    }
  });

  $('.tab-headline').click(function() {
    $(this)
      .find('.toggle-icon')
      .toggleClass('rotate-half')
      .toggleClass('rotate-centered');
  });

  /* ==============
        Dashboard Tabs - END
    ================ */

  /* ==============
        Footer - Start
    ================ */

  $('.footer-section-header').click(function() {
    if (isViewport('small') || isViewport('medium')) {
      var parent = $(this).parent();
      parent
        .find('.footer-links')
        .stop()
        .slideToggle();
      parent.find('.icon-arrow-bottom').toggleClass('rotate-half');
    }
  });

  /* ==============
        Footer - END
    ================ */

  /* ==============
        Navigation Animation - START
    ================ */
  /*
        $('#nav-mywilo').mouseenter(function() {
            if($(document).width() > 991) {
                $('.nav-mywilo-overview').show();
            }
        });
    
        $('#storage-nav').mouseleave(function() {
            $('.nav-mywilo-overview').hide();
        });
    */

  // On navelement hover show submenu & animate arrow
  $('.nav-element').hover(
    function() {
      if (isViewport('large') || isViewport('xlarge')) {
        $('[data-menu=' + $(this).attr('id') + ']').show();
        $('.mega-dropdown-menu-arrow')
          .stop()
          .animate({
            left:
              $(this).position().left +
              (document.body.clientWidth - $('#mobile-menu').width()) / 2 +
              $(this)
                .find('.nav-level1-element-text')
                .width() /
                2 -
              10
          });
      }
    },
    function() {
      if (isViewport('large') || isViewport('xlarge')) {
        $('.sub-menu').hide();
      }
    }
  );

  $('.nav-element').on('click', function() {
    var $link = $(this).find('a');
    if ($link.attr('target') &&  $link.attr('target') === '_blank') {
      return;
    } else {
      $('li.is-active').removeClass('is-active');
      $(this).addClass('is-active');
    }
  });

  // Hide submenu on mouseleave
  $('.sub-menu').hover(
    function() {
      if (isViewport('large') || isViewport('xlarge')) {
        $(this).show();
      }
    },
    function() {
      if (isViewport('large') || isViewport('xlarge')) {
        $('.sub-menu').hide();
      }
    }
  );

  // Mobile menu back to parent menu
  $('.sub-menu-title').click(function() {
    if (isViewport('small') || isViewport('medium')) {
      $('.sub-menu-title')
        .parents('.sub-menu')
        .removeClass('is-active');
      toggleMobileNavVisible(true); //fade in nav
    }
  });

  // Mobile menu push submenu
  $('.nav-element').click(function(e) {
    if (isViewport('small') || isViewport('medium')) {
      var submenu = $('[data-menu=' + $(this).attr('id') + ']');
      if (submenu.length) {
        e.preventDefault();
        submenu.addClass('is-active');
        toggleMobileNavVisible(false); //fade out nav
      }
    }
  });

  function toggleMobileNavVisible(visible) {
    $('.navbar-nav > .nav-element')
      .parent()
      .toggleClass(
        'page-fade-out',
        visible !== undefined ? !visible : undefined
      );
  }

  // Mobile burger menu animation
  $('.navbar-toggle').click(function(e) {
    toggleNav();
  });
  function toggleNav() {
    $('body').toggleClass('page-stop-scrolling');
    $('.sub-menu.is-active').removeClass('is-active');
    $('.navbar-toggle, .navbar-nav').toggleClass('is-active');
    $('.nav__search').addClass('nav__search--hidden');
    dismissOverlay();
    const active = $('.navbar-toggle').hasClass('is-active');
    $('.overlay').toggleClass('active', active);
    toggleMobileNavVisible(active);
  }

  $('.overlay').click(function() {
    $('.nav__search').addClass('nav__search--hidden');
    if ($(this).hasClass('active') && !$(this).hasClass('search')) {
      toggleNav();
    }
    dismissOverlay();
  });

  function dismissOverlay() {
    $('.generate-pdf-form-wrapper').removeClass('active');
    $('.searchbar-flyout').hide();
    $('.searchbar-results-wrapper').hide();
    $('.quicklinks-wrapper').hide();
    $('#nav-searchbar span')
      .addClass('icon-search')
      .removeClass('icon-close');
    $('.overlay').removeClass('active search');
  }

  //Cart Toggle
  $('#cart').click(function() {
    $('.cart-overview')
      .stop()
      .slideToggle();
  });

  /* ==============
        Navigation Animation - END
    ================ */

  /* ==============
        Survey - START
    ================ */

  if (ifExists($('.survey label'))) {
    $('.survey label').hover(
      function() {
        $(this).addClass('active');
        $(this)
          .find('.wilo-icon')
          .show();
      },
      function() {
        $(this).removeClass('active');
        $(this)
          .find('.wilo-icon')
          .hide();
      }
    );

    $('.survey label').click(function() {
      $('.survey-wrapper').addClass('js-second-step');
    });
  }

  /* ==============
        Survey - END
    ================ */

  /* ==============
        Videos - START
    ================ */

  // Toggle controls on video click
  $('.welcome-video, .video-tag').click(function() {
    var elem = $(this).get(0);
    var controls = $(this)
      .parent()
      .parent()
      .find('.video-player-control');
    if (elem.paused) {
      elem.play();
      $(this).css({
        opacity: '1',
        transition: 'all 0.5'
      });
      controls.fadeOut();
    } else {
      elem.pause();
      $(this).css({
        opacity: '0.2',
        transition: 'all 0.5'
      });
      controls.fadeIn();
    }
  });

  /* // Auto open video pop up on dashboard welcome TODO - Cookie Saving !
    if ($('#welcome-popup:not(".video-popup")').length > 0) {
        $.magnificPopup.open({
            items: {
                src: '#welcome-popup'
            },
            type: 'inline',
            closeBtnInside: true,
            closeOnContentClick: false,
            midClick: false
        });
    } */

  // Auto open video pop up
  if ($('.video-popup').length > 0) {
    $('.video-playbutton').click(function() {
      $.magnificPopup.open({
        items: {
          src: '.video-popup'
        },
        type: 'inline',
        closeBtnInside: true,
        closeOnContentClick: false,
        midClick: false
      });
    });
  }

  /* ==============
        Videos - END
    ================ */

  /* ==============
        BADGE
    ================ */

  var initBadge = (function() {
    var badge = document.querySelector('.globalBadge__close');
    if (badge) {
      if (!getCookie('badge')) {
        badge.parentNode.classList.remove('hidden');
        badge.addEventListener('click', function() {
          this.parentNode.classList.add('hidden');
          createCookie('badge', 'true', 1000);
        });
      }
    }
  })();
});
/* ==============
    Document Ready - END
================ */

// Target Group Stage

let initStage = stage => {
  var items = stage.querySelectorAll('.tgStage__navItem');

  stage.onmouseenter = function() {
    moveItems();
  };
  stage.onmouseleave = function() {
    moveItems();
  };

  function moveItems() {
    for (var i = 0; i < items.length; i++) {
      var toggleItemMove = getToggleItemMove(i);
      setTimeout(toggleItemMove, i * 40);
    }
  }

  function getToggleItemMove(i) {
    var item = items[i];
    return function() {
      item.classList.toggle('tgStage__navItem--active');
    };
  }
};

document.querySelectorAll('.tgStage__element').forEach(stage => {
  initStage(stage);
});

initFlyout();

const initModules = () => {
  initTextOnImage();
  initLoginButton();
  initEcolutionCountdown();
  initEcolutionAccordion();
  initBSRPumpReplacementTeaser();
  initBSRFaultSignalAssistance();
  initBsrAnchorNavigation();
  initBsrSubNavigation();
  initBsrMainNavigation();
}

if(document.readyState === 'loading') {

  document.addEventListener("DOMContentLoaded", event => {
    initModules();
  });
} else {
    initModules();
}

document.addEventListener('mwf-ajax-finished', event => {
  initEcolutionForm();
});

/** prevents all webforms timeouts */
var webformsInterval = null;
document.addEventListener("mwf-ajax-finished", event => {
  let form = event.detail.$dest[0];
  if (webformsInterval) {
    clearInterval(webformsInterval);
  }
  webformsInterval = setInterval(function () {
          let action = jQuery(form).attr("action");
          let url = action.substr(0, action.indexOf('servlet')) + 'servlet/rest?_fd';
          let indexOfSessionId = action.indexOf(';webforms=');
          if (indexOfSessionId !== -1) {
              let indexOfQuestionmark = action.indexOf('?');
              url += action.substring(indexOfSessionId, indexOfQuestionmark);
          }
  
          $.ajax({
              type: 'POST',
              url: url,
              timeout: 5000,
              xhrFields: {
                  withCredentials: true,
              },
          }).then(
              function (data) {
              },
              function (error) {
              }
          );
      }, 300000);
});