export function showOffCanvas(message) {
    let offCanvasContainer = document.getElementById('offcanvas');
    let offCanvasAlert = document.getElementById('off-canvas-alert');
    let offCanvasLabel = document.getElementById('off-canvas-alert-content');

    offCanvasContainer.style.display = 'block';
    offCanvasAlert.style.display = 'block';
    offCanvasLabel.insertAdjacentElement('beforeend', message);

    scrollToTop();
    addCloseOffCanvasEventListener();
}

export function hideOffCanvas() {
    let offCanvasContainer = document.getElementById('offcanvas');
    let offCanvasAlert = document.getElementById('off-canvas-alert');
    let offCanvasLabel = document.getElementById('off-canvas-alert-content');

    offCanvasContainer.style.display = 'none';
    offCanvasAlert.style.display = 'none';
    offCanvasLabel.innerText = '';

    scrollToTop();
    removeCloseOffCanvasEventListener();
}

export function scrollToTop() {
    window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': document.body.offsetTop
    });
}

export function addCloseOffCanvasEventListener() {
    const btnClose = document.getElementById('close-off-canvas');

    btnClose.addEventListener('click', (event) => {
        event.preventDefault();
        hideOffCanvas();
    });
}

export function removeCloseOffCanvasEventListener() {
    const btnClose = document.getElementById('close-off-canvas');

    if(btnClose !== undefined && btnClose !== null) {
        btnClose.removeEventListener('click', (event) => {
            event.preventDefault();
        });
    }
}