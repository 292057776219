/* ============== pump finder teaser - start ================ */

$(document).ready(function () {
    let val;
    let submitPumpFinderBtn = $('#submit-pump-finder-teaser');
    submitPumpFinderBtn.attr('disabled', true);

    $('.pump-finder-teaser-module-dropdown').each(function () {
        if (!$(this).hasClass('category-selection')) {
            $(this).hide();
        }
    });

    $('.category-selection select').change(function () {
        val = JSON.parse($(this).val());

        if(val.category === '' && val.link === '') {
            submitPumpFinderBtn.attr('disabled', true);
        }

        $('.pump-finder-teaser-module-dropdown').each(function () {
            if (!$(this).hasClass('category-selection')) {
                $(this).hide();
                if ($(this).data('list') === val.category) {
                    $(this).show();
                    submitPumpFinderBtn.attr('disabled', false);
                    submitPumpFinderBtn.attr('href', val.link);
                }
            }
        });
    });

    $('.pump-finder-teaser-module-dropdown select').change(function () {
        if (!($(this).parent().hasClass('category-selection'))) {
            if ($(this).val() !== '') {
                submitPumpFinderBtn.attr('disabled', false);
                submitPumpFinderBtn.attr('href', $(this).val())
            } else {
                submitPumpFinderBtn.attr('href', val.link);
            }
        }
    });
});

/* ============== pump finder teaser - end ================ */