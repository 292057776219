export default function initEcolutionCountdown() {
    init();

    function calculateDueMs(element) {
        try {
            const dueDate = Date.parse(element.dataset.dueDate);
            const difference = dueDate - new Date().getTime();
            return Math.max(difference, 0) || 0;
        } catch {
            return 0;
        }
    }

    function splitIntoChunks(difference) {
        const totalSeconds = Math.floor(difference / 1000);
        const secondsPerHour = 3600;
        const secondsPerDay = 86400;

        return {
            seconds: totalSeconds % 60,
            minutes: Math.floor((totalSeconds % secondsPerHour)  / 60),
            hours: Math.floor((totalSeconds % secondsPerDay) / secondsPerHour),
            days: Math.floor(totalSeconds / secondsPerDay),
        };           
    }

    function updateTimer(element) {
        const values = splitIntoChunks(calculateDueMs(element));
        
        Object.entries(values).forEach(([key, value]) => {
            const valueElement = element.querySelector(`.countdown__digit--${key} .countdown__value`);
            requestAnimationFrame(() => {
                valueElement?.setAttribute('data-value', value.toString().padStart(2, "0"));
            });
        });
    }

    function init() {
        const onIntersection = (element, entries) => {
            const timer = element.dataset.timer;
            if(entries[0].isIntersecting && !timer) {
                updateTimer(element);
                const timerId = setInterval(
                    () => updateTimer(element)
                    , 1000
                );
                element.setAttribute('data-timer', timerId);
            } else if(!entries[0].isIntersecting && timer) {
                clearInterval(timer);
                element.removeAttribute("data-timer");
            }
        }

        const elements = document.querySelectorAll('.ecolution-countdown');
        elements.forEach((element) => {
            const observer = new IntersectionObserver(
                onIntersection.bind(this, element), 
                {
                    rootMargin: `200px`,
                    threshold: 0.1,
                }
            );
            observer.observe(element);
        });
    }
}