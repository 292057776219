import {getRequest} from "../general/xhr-requests"

var atcBox = (function () {

    var boxes = document.querySelectorAll('.atcBox');

    if (boxes.length) {

        function initAtcBox(box) {
            var sku = box.dataset.sku;
            getRequest('/api/' + window.wiloconfig.country.locale + '/products?skus=' + sku, function (data, xhr) {
                if (xhr.status !== 404) {
                    if (xhr.status !== 200) {
                        getCartById(cartId);
                    } else {
                        const atcBox = document.querySelector('.atcBox');
                        if (xhr.status === 200 && data !== '[]' ) {
                            JSON.parse(data).forEach(product => {
                                atcBox.querySelector('.atcBox__load').classList.add('hidden');
                                atcBox.querySelector('.atcBox__productName').innerHTML = product.name;
                                atcBox.querySelector('.atcBox__sku').innerHTML = product.sku;
                                atcBox.querySelector('.atcBox__currency').innerHTML = product.pricing.currency;
                                atcBox.querySelector('.atcBox__price').innerHTML = product.pricing.amount;
                            });
                        } else {
                            atcBox.style.display = 'none';
                        }
                    }
                }
            });
        }

        var initQuantityModule = (function () {
            const module = document.querySelector('.quantity-module');
            var input = module.querySelector('.quantityModule__value');
            module.querySelector('.add').addEventListener('click', function () {
                input.value = parseInt(input.value) + 1;
            });
            module.querySelector('.remove').addEventListener('click', function () {
                input.value = parseInt(input.value) - 1;
            });
        })();

        for (var i = 0; i < boxes.length; i++) {
            initAtcBox(boxes[i]);
        }

    }

})();
