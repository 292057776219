import { ifExists } from "../main";
import {getRequest, postRequest} from "../general/xhr-requests";

if (ifExists($('.add-to-cart-btn'))) {

    var webshopIndex;
    var webshopCartItem = $('.collapse-container-inner tbody tr').clone(true);
    var requesting;

    $('.webshop-series-item').removeClass('js-disabled');

    $('.series-webshop').click(function () {
        if ($('#webshop tbody tr').length === 1) {
            var items = $(this).data('skus');
            getRequest('/api/' + window.wiloconfig.country.locale + '/products?skus=' + items, function (data, xhr) {
                if (xhr.status === 404) {
                    console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                } else if (xhr.status !== 200) {
                    getCartById(cartId);
                } else {
                    var row = $('#webshop tbody tr');
                    JSON.parse(data).forEach(elem => {
                        createRow(elem, row);
                    });
                    row.first().remove();
                }
            });
        }
    });

    function createRow(elem, row) {
        var newRow = row.first().clone(true);
        var price = elem.pricing;
        newRow.find('.product-name').text(elem.name);
        newRow.find('.add-to-cart-btn').attr('data-sku', elem.sku);
        newRow.find('.price-excl-vat span').text(price.subTotal + ' ' + price.currency);
        newRow.find('.price-total').text(price.amount + ' ' + price.currency);
        newRow.find('.product-number span').text(elem.sku);
        row.parent().append(newRow);
    }

    // Toggle collapse container
    $(".collapse-container-btn").click(function (e) {
        if (!requesting) {
            var items = $(this).data('skus');
            var webshopItems = $('.webshop-series-item');
            if ($(".collapse-container-btn").index($(this)) === webshopIndex) {
                $(".webshop-collapse-container").stop().slideUp();
                webshopItems.removeClass('active');
                $('.webshop-series-item').removeClass('js-disabled');
                webshopIndex = -1;
            } else {
                var container = $('.webshop-collapse-container');
                var clone = container.clone(true);
                var currElem = $(this);
                requesting = true;
                getRequest('/api/' + window.wiloconfig.country.locale + '/products?skus=' + items, function (data, xhr) {
                    webshopIndex = $(".collapse-container-btn").index(currElem);
                    requesting = false;
                    if (xhr.status === 404) {
                        console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                    } else if (xhr.status !== 200) {
                        getCartById(cartId);
                    } else {
                        if (JSON.parse(data).length > 0) {
                            webshopItems.addClass('js-disabled');
                            container.stop().slideUp(function () {
                                $(this).remove();
                                clone.insertAfter(webshopItems[getNextCollapseContainerPosition()]).hide();
                                $('.collapse-container-inner tbody tr').remove();
                                webshopItems.removeClass('active');
                                initializeContainer();
                                JSON.parse(data).forEach(elem => {
                                    createProductRow(elem);
                                });
                                var currElem = $(webshopItems[webshopIndex]);
                                currElem.addClass('active').removeClass('js-disabled');
                                clone.slideDown()
                                    .find('.arrow')
                                    .css('left', Math.round(currElem.position().left + currElem.width() / 2 - $('.webshop-collapse-container').position().left) + 'px');
                            });
                        } else {
                            webshopIndex = '';
                        }
                    }
                });
            }
        }
    });

    // Disable not buyable products
    $('.webshop-series-item').each(function () {
        var items = $(this).find('.collapse-container-btn').data('skus');
        if (items === "" || items === undefined) {
            $(this).addClass('js-disabled');
        }
    });


    // On window resize close collapse container
    $(window).on('resize', function () {
        if (ifExists($(".webshop-collapse-container:visible"))) {
            $(".webshop-collapse-container").hide();
            $('.webshop-series-item').removeClass('active');
            $('.webshop-series-item').removeClass('js-disabled');
        }
    });

    var initOutOfFocus = function() {
        if(document.querySelector('.webshop-series-overview')) {
            document.addEventListener('click', collapseContainerOutOfFocus);
        }
    }();

    // Close collapse container
    function closeCollapseContainer() {
        $('.webshop-collapse-container').slideUp();
        $('.webshop-series-item').removeClass('active');
        $('.webshop-series-item').removeClass('js-disabled');
    }

    function collapseContainerOutOfFocus(e) {
        var collapseContainer = document.querySelector('.webshop-collapse-container');
        var clickedElement = e.target
        do {
            if (clickedElement == collapseContainer) {
                return;
            }
            clickedElement = clickedElement.parentNode;
        } while (clickedElement);
        closeCollapseContainer();
    }

    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            closeCollapseContainer();
        }
    });

    $('.webshop-collapse-container .close').click(function () {
        closeCollapseContainer();
    });

    // Add pump to cart
    $('.webshop-collapse-container').on('click', '.add-to-cart-btn', function () {
        var count = parseInt($(this).parents('tr').find('input').val());

        if (count > 0) {
            var quantity = JSON.stringify({
                'count': count
            });
            postRequest('/api/' + window.wiloconfig.country.locale + '/cart/' + cartId + '/lineitem?sku=' + $(this).data('sku'), quantity, function (data, xhr) {
                var cart = JSON.parse(data);
                localStorage.setItem('cartCount', cart.count);
                $('.cart-count').text(cart.count);
                // Never use jQuery !!!
                if (cart.error !== undefined) {
                    if (cart.error === 'eCommerce.errors.maxLineItems') {
                        showOffCanvasLayer();
                    }
                }
                if (xhr.status === 404) {
                    console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                }
                const pageBody = document.querySelector('body');
                window.scrollTo({
                    'behavior': 'smooth',
                    'left': 0,
                    'top': pageBody.offsetTop
                });
            });
        }

    });

    // Init new collapse container
    function initializeContainer() {
        var currElem = $($('.webshop-series-item')[webshopIndex]);
        var container = $('.collapse-container-inner');
        var itemCount = (currElem.find('.collapse-container-btn')
            .data('skus')).toString().split(',').length;
        container.find('.series-image img')
            .attr('src', currElem.find('.series-image img').attr('src'));
        container.find('.series-title')
            .text(currElem.find('.series-title').text());
        container.find('.product-count').text(itemCount);
    }

    // Create product rows and insert data from request
    function createProductRow(elem) {
        var price = elem.pricing;
        // Schema.org
        webshopCartItem.find('.seo-product-name').text(elem.name);
        webshopCartItem.find('.seo-product-image').attr('src', elem.images[0]);
        webshopCartItem.find('.seo-product-image').attr('alt', elem.name);
        webshopCartItem.find('.seo-product-sku').text(elem.sku);
        webshopCartItem.find('.seo-product-model').text(elem.model);
        webshopCartItem.find('.seo-product-description').text(elem.description);
        webshopCartItem.find('.seo-product-price').text(price.subTotal + ' ' + price.currency);

        webshopCartItem.find('.product-name').text(elem.name);
        webshopCartItem.find('.add-to-cart-btn').attr('data-sku', elem.sku);
        webshopCartItem.find('.price-excl-vat span').text(price.subTotal + ' ' + price.currency);
        webshopCartItem.find('.price-total').text(price.amount + ' ' + price.currency);
        webshopCartItem.find('.product-number span').text(elem.sku);
        var lineItem = $('.collapse-container-inner tbody').append(webshopCartItem);
        webshopCartItem = $('.collapse-container-inner tbody tr').first().clone(true);
    }

    // Calculate position of the next collapse container
    function getNextCollapseContainerPosition() {
        var elemCount = $('.webshop-series-item').length;
        var width = window.innerWidth;
        var index;
        if (width > 991) {
            index = [webshopIndex + 2 - webshopIndex % 3]
        } else if (width > 767) {
            index = [webshopIndex + 1 - webshopIndex % 2]
        } else {
            index = webshopIndex;
        }
        if (index[0] > elemCount - 1) {
            index = elemCount - 1;
        }
        return index;
    }

    // Remove one from Quantity
    $('.webshop-collapse-container').on('click', '.quantity-module .remove', function () {
        var quantity = $(this).siblings('input');
        if (quantity.val() > 1) {
            quantity.val(parseInt(quantity.val()) - 1);
            $(this).parent().find('.add').removeClass('disabled');
        }
    });

    // Add one to Quantity
    $('.webshop-collapse-container').on('click', '.quantity-module .add', function () {
        var quantity = $(this).siblings('input');

        if (quantity.val() >= 1) {
            quantity.val(parseInt(quantity.val()) + 1);
            $(this).parent().find('.remove').removeClass('disabled');
        }
        validateQuantity(quantity);

    });


    // If quantity has an invalid value validate it
    function validateQuantity(quantity) {
        if (quantity.val() < 2) {
            quantity.val(1);
            quantity.siblings('.remove').addClass('disabled');if (ifExists($('.add-to-cart-btn'))) {

                var webshopIndex;
                var webshopCartItem = $('.collapse-container-inner tbody tr').clone(true);
                var requesting;

                $('.webshop-series-item').removeClass('js-disabled');

                $('.series-webshop').click(function () {
                    if ($('#webshop tbody tr').length === 1) {
                        var items = $(this).data('skus');
                        getRequest('/api/' + window.wiloconfig.country.locale + '/products?skus=' + items, function (data, xhr) {
                            if (xhr.status === 404) {
                                console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                            } else if (xhr.status !== 200) {
                                getCartById(cartId);
                            } else {
                                var row = $('#webshop tbody tr');
                                JSON.parse(data).forEach(elem => {
                                    createRow(elem, row);
                                });
                                row.first().remove();
                            }
                        });
                    }
                });

                function createRow(elem, row) {
                    var newRow = row.first().clone(true);
                    var price = elem.pricing;
                    newRow.find('.product-name').text(elem.name);
                    newRow.find('.add-to-cart-btn').attr('data-sku', elem.sku);
                    newRow.find('.price-excl-vat span').text(price.subTotal + ' ' + price.currency);
                    newRow.find('.price-total').text(price.amount + ' ' + price.currency);
                    newRow.find('.product-number span').text(elem.sku);
                    row.parent().append(newRow);
                }

                // Toggle collapse container
                $(".collapse-container-btn").click(function (e) {
                    if (!requesting) {
                        var items = $(this).data('skus');
                        var webshopItems = $('.webshop-series-item');
                        if ($(".collapse-container-btn").index($(this)) === webshopIndex) {
                            $(".webshop-collapse-container").stop().slideUp();
                            webshopItems.removeClass('active');
                            $('.webshop-series-item').removeClass('js-disabled');
                            webshopIndex = -1;
                        } else {
                            var container = $('.webshop-collapse-container');
                            var clone = container.clone(true);
                            var currElem = $(this);
                            requesting = true;
                            getRequest('/api/' + window.wiloconfig.country.locale + '/products?skus=' + items, function (data, xhr) {
                                webshopIndex = $(".collapse-container-btn").index(currElem);
                                requesting = false;
                                if (xhr.status === 404) {
                                    console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                                } else if (xhr.status !== 200) {
                                    getCartById(cartId);
                                } else {
                                    if (JSON.parse(data).length > 0) {
                                        webshopItems.addClass('js-disabled');
                                        container.stop().slideUp(function () {
                                            $(this).remove();
                                            clone.insertAfter(webshopItems[getNextCollapseContainerPosition()]).hide();
                                            $('.collapse-container-inner tbody tr').remove();
                                            webshopItems.removeClass('active');
                                            initializeContainer();
                                            JSON.parse(data).forEach(elem => {
                                                createProductRow(elem);
                                            });
                                            var currElem = $(webshopItems[webshopIndex]);
                                            currElem.addClass('active').removeClass('js-disabled');
                                            clone.slideDown()
                                                .find('.arrow')
                                                .css('left', Math.round(currElem.position().left + currElem.width() / 2 - $('.webshop-collapse-container').position().left) + 'px');
                                        });
                                    } else {
                                        webshopIndex = '';
                                    }
                                }
                            });
                        }
                    }
                });

                // Disable not buyable products
                $('.webshop-series-item').each(function () {
                    var items = $(this).find('.collapse-container-btn').data('skus');
                    if (items === "" || items === undefined) {
                        $(this).addClass('js-disabled');
                    }
                });


                // On window resize close collapse container
                $(window).on('resize', function () {
                    if (ifExists($(".webshop-collapse-container:visible"))) {
                        $(".webshop-collapse-container").hide();
                        $('.webshop-series-item').removeClass('active');
                        $('.webshop-series-item').removeClass('js-disabled');
                    }
                });

                var initOutOfFocus = function() {
                    if(document.querySelector('.webshop-series-overview')) {
                        document.addEventListener('click', collapseContainerOutOfFocus);
                    }
                }();

                // Close collapse container
                function closeCollapseContainer() {
                    $('.webshop-collapse-container').slideUp();
                    $('.webshop-series-item').removeClass('active');
                    $('.webshop-series-item').removeClass('js-disabled');
                }

                function collapseContainerOutOfFocus(e) {
                    var collapseContainer = document.querySelector('.webshop-collapse-container');
                    var clickedElement = e.target
                    do {
                        if (clickedElement == collapseContainer) {
                            return;
                        }
                        clickedElement = clickedElement.parentNode;
                    } while (clickedElement);
                    closeCollapseContainer();
                }

                $(document).keyup(function (e) {
                    if (e.keyCode === 27) {
                        closeCollapseContainer();
                    }
                });

                $('.webshop-collapse-container .close').click(function () {
                    closeCollapseContainer();
                });

                // Add pump to cart
                $('.webshop-collapse-container').on('click', '.add-to-cart-btn', function () {
                    var count = parseInt($(this).parents('tr').find('input').val());

                    if (count > 0) {
                        var quantity = JSON.stringify({
                            'count': count
                        });
                        postRequest('/api/' + window.wiloconfig.country.locale + '/cart/' + cartId + '/lineitem?sku=' + $(this).data('sku'), quantity, function (data, xhr) {
                            var cart = JSON.parse(data);
                            localStorage.setItem('cartCount', cart.count);
                            $('.cart-count').text(cart.count);
                            // Never use jQuery !!!
                            if (cart.error !== undefined) {
                                if (cart.error === 'eCommerce.errors.maxLineItems') {
                                    showOffCanvasLayer();
                                }
                            }
                            if (xhr.status === 404) {
                                console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
                            }
                            const pageBody = document.querySelector('body');
                            window.scrollTo({
                                'behavior': 'smooth',
                                'left': 0,
                                'top': pageBody.offsetTop
                            });
                        });
                    }

                });

                // Init new collapse container
                function initializeContainer() {
                    var currElem = $($('.webshop-series-item')[webshopIndex]);
                    var container = $('.collapse-container-inner');
                    var itemCount = (currElem.find('.collapse-container-btn')
                        .data('skus')).toString().split(',').length;
                    container.find('.series-image img')
                        .attr('src', currElem.find('.series-image img').attr('src'));
                    container.find('.series-title')
                        .text(currElem.find('.series-title').text());
                    container.find('.product-count').text(itemCount);
                }

                // Create product rows and insert data from request
                function createProductRow(elem) {
                    var price = elem.pricing;
                    // Schema.org
                    webshopCartItem.find('.seo-product-name').text(elem.name);
                    webshopCartItem.find('.seo-product-image').attr('src', elem.images[0]);
                    webshopCartItem.find('.seo-product-image').attr('alt', elem.name);
                    webshopCartItem.find('.seo-product-sku').text(elem.sku);
                    webshopCartItem.find('.seo-product-model').text(elem.model);
                    webshopCartItem.find('.seo-product-description').text(elem.description);
                    webshopCartItem.find('.seo-product-price').text(price.subTotal + ' ' + price.currency);

                    webshopCartItem.find('.product-name').text(elem.name);
                    webshopCartItem.find('.add-to-cart-btn').attr('data-sku', elem.sku);
                    webshopCartItem.find('.price-excl-vat span').text(price.subTotal + ' ' + price.currency);
                    webshopCartItem.find('.price-total').text(price.amount + ' ' + price.currency);
                    webshopCartItem.find('.product-number span').text(elem.sku);
                    var lineItem = $('.collapse-container-inner tbody').append(webshopCartItem);
                    webshopCartItem = $('.collapse-container-inner tbody tr').first().clone(true);
                }

                // Calculate position of the next collapse container
                function getNextCollapseContainerPosition() {
                    var elemCount = $('.webshop-series-item').length;
                    var width = window.innerWidth;
                    var index;
                    if (width > 991) {
                        index = [webshopIndex + 2 - webshopIndex % 3]
                    } else if (width > 767) {
                        index = [webshopIndex + 1 - webshopIndex % 2]
                    } else {
                        index = webshopIndex;
                    }
                    if (index[0] > elemCount - 1) {
                        index = elemCount - 1;
                    }
                    return index;
                }

                // Remove one from Quantity
                $('.webshop-collapse-container').on('click', '.quantity-module .remove', function () {
                    var quantity = $(this).siblings('input');
                    if (quantity.val() > 1) {
                        quantity.val(parseInt(quantity.val()) - 1);
                        $(this).parent().find('.add').removeClass('disabled');
                    }
                });

                // Add one to Quantity
                $('.webshop-collapse-container').on('click', '.quantity-module .add', function () {
                    var quantity = $(this).siblings('input');

                    if (quantity.val() >= 1) {
                        quantity.val(parseInt(quantity.val()) + 1);
                        $(this).parent().find('.remove').removeClass('disabled');
                    }
                    validateQuantity(quantity);

                });


                // If quantity has an invalid value validate it
                function validateQuantity(quantity) {
                    if (quantity.val() < 2) {
                        quantity.val(1);
                        quantity.siblings('.remove').addClass('disabled');
                    } else {
                        if (quantity.val() >= window.webShopConfig.maxCountPerLineItem) {
                            quantity.val(window.webShopConfig.maxCountPerLineItem);
                            quantity.siblings('.add').addClass('disabled');
                        }
                    }
                }

                function showOffCanvasLayer() {
                    var offCanvasContainer = document.getElementById('offcanvas');
                    var offCanvasAlert = document.getElementById('off-canvas-alert');
                    var offCanvasLabel = document.getElementById('off-canvas-alert-content');
                    offCanvasContainer.style.display = 'block';
                    offCanvasAlert.style.display = 'block';
                    offCanvasLabel.innerText = window.wiloconfig.MY_WILO_TRANSLATION_KEYS.eCommerce.errors.maxLineItems;
                    window.scrollTo({
                        'behavior': 'smooth',
                        'left': 0,
                        'top': offCanvasContainer.offsetTop
                    });
                }

                $(document).on('keyup keydown', 'input', function () {
                    var count = parseInt($(this).val());
                    if (count > window.webShopConfig.maxCountPerLineItem) {
                        $(this).val(window.webShopConfig.maxCountPerLineItem);
                    }
                });
            }
        } else {
            if (quantity.val() >= window.webShopConfig.maxCountPerLineItem) {
                quantity.val(window.webShopConfig.maxCountPerLineItem);
                quantity.siblings('.add').addClass('disabled');
            }
        }
    }

    function showOffCanvasLayer() {
        var offCanvasContainer = document.getElementById('offcanvas');
        var offCanvasAlert = document.getElementById('off-canvas-alert');
        var offCanvasLabel = document.getElementById('off-canvas-alert-content');
        offCanvasContainer.style.display = 'block';
        offCanvasAlert.style.display = 'block';
        offCanvasLabel.innerText = window.wiloconfig.MY_WILO_TRANSLATION_KEYS.eCommerce.errors.maxLineItems;
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': offCanvasContainer.offsetTop
        });
    }

    $(document).on('keyup keydown', 'input', function () {
        var count = parseInt($(this).val());
        if (count > window.webShopConfig.maxCountPerLineItem) {
            $(this).val(window.webShopConfig.maxCountPerLineItem);
        }
    });
}
