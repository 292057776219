import Glide from '@glidejs/glide'

export const initThreeColumnGalleries = function () {
  const galleries = document.querySelectorAll(".three-column-gallery");
  console.log(galleries);
  if (galleries.length > 0) {
    galleries.forEach((gallery) => {
      const glideControls = gallery.querySelector("[data-glide-el='controls']");
      const modal = gallery.querySelector(".modal");
      const targetsWrapper = modal.querySelector(".modal-content");
      const modalContentContainers = [...targetsWrapper.children];
      const closeButton = modal.querySelector(".close");
      let modalImageIndex = 0;

      closeButton.onclick = function () {
        modal.style.display = "none";
      };

      const gallerySlides = gallery.querySelectorAll(".glide__slide");

      if (gallerySlides.length > 3) {
        const config = {
          type: "carousel",
          startAt: 0,
          perView: 3,
          animationDuration: 800,
          gap: 24,
          breakpoints: {
            1000: {
              perView: 2,
            },
            600: {
              perView: 1,
            },
          },
        };

        const uninitializedSlideClassName = "gallery__slide--uninitialized";
        const uninitializedSlidesWrapperClassName =
          "gallery__slides--uninitialized";

        //remove css from slides
        gallerySlides.forEach(function (slide) {
          slide.classList.remove(uninitializedSlideClassName);
        });

        //remove css from slides-wrapper
        Array.prototype.forEach.call(
          gallery.getElementsByClassName(uninitializedSlidesWrapperClassName),
          function (slidesWrapper) {
            slidesWrapper.classList.remove(uninitializedSlidesWrapperClassName);
          }
        );

        //show buttons
        glideControls.classList.remove("display-none");
        new Glide(gallery, config).mount();
      }

      const updateModalVisibility = (activeIndex) => {
        [...modalContentContainers].forEach(
          (container) => (container.style.display = "none")
        );
        modalContentContainers[activeIndex].style.display = "block";
        modal.style.display = "block";
      };

      //ensure that the cloned elements have the onClick as well
      gallery.querySelectorAll(".glide__slide").forEach((slide) => {
        slide.addEventListener("click", function (event) {
          const elem = event.target;
          modalImageIndex = elem.getAttribute("data-index");
          updateModalVisibility(modalImageIndex);
        });
      });

      //enable navigation within modal
      [...modalContentContainers].forEach((container) => {
        container.querySelector(".prev").addEventListener("click", () => {
          modalImageIndex =
            modalImageIndex == 0
              ? gallerySlides.length - 1
              : modalImageIndex - 1;
          updateModalVisibility(modalImageIndex);
          glideControls.querySelector(".glide__arrow-left").click();
        });
        container.querySelector(".next").addEventListener("click", () => {
          modalImageIndex = ++modalImageIndex % gallerySlides.length;
          updateModalVisibility(modalImageIndex);
          glideControls.querySelector(".glide__arrow-right").click();
        });
      });
    });
  }
};
