import "magnific-popup"

// Using http://dimsemenov.com/plugins/magnific-popup/documentation.html

var openIframeMagnificPopup = function(iframeSrc,title)  {
  title = typeof title !== 'undefined' ? title : "";
  var source = typeof iframeSrc !== 'undefined' && iframeSrc.indexOf("autostart") != -1 ? iframeSrc.replace('autostart=false','autostart=true') : iframeSrc;

  return $.magnificPopup.open({
    items: {
      src: source
    },
    type: 'iframe',
    iframe: {
      markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-close"></div>'+
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '<div class="mfp-title">' + title + '</div>'+
              '</div>'
    }
  });
}


$(document).on('click', '.video-link', function (e) {
  e.preventDefault();
  var url = $(this).attr('href');
  var title = $(this).attr('title');
  openIframeMagnificPopup(url,title);
});

$(document).on('click', '.lightbox-video', function(e) {
    e.preventDefault();
    var title = $(this).attr('title');
    var iframeSrc = $(this).next('.video-lightbox-content').find('iframe').attr('src');
    openIframeMagnificPopup(iframeSrc,title);
});

$(document).ready(function(){
  // Type is defined at Element
  // example: <a class="mfp-image image-link" data-effect="ANIMATE CSS ANIMATION-NAME">Open image</a>

  $('.image-link').magnificPopup({
    removalDelay: 300,
    type: 'image',
    callbacks: {
    beforeOpen: function() {
      // Triggers Animate CSS Animation
       this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated ' + this.st.el.attr('data-effect'));
    }
  },
  });

  $('.video-link').magnificPopup({
    removalDelay: 300,
    type: 'inline',
    callbacks: {
    beforeOpen: function() {
      // Triggers Animate CSS Animation
      var target = $(this.st.el[0].hash);
      target.addClass('animated ' + this.st.el.attr('data-effect'));
    }
  },
  });

  //TODO: Das ist nur ein Hotfix - Sollte sich eine Pixlschubser nochmal ansehen/vervollständigen ;)
  $('.lightbox-image-gallery').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
      delegate: '.lightbox-gallery-item', // the selector for gallery item
      type: 'image',

      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tPrev: '', // title for left button
        tNext: '', // title for right button
        tCounter: '' // markup of counter
      }
    });
  });

});
