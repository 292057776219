export default function initEcolutionAccordion() {
    const elements = document.querySelectorAll('.ecolution-accordion');
    elements.forEach((element) => {
        const panels = element.querySelectorAll('.accordion-pannel');
        const baseId = `accordion_panel_${crypto.randomUUID ? crypto.randomUUID() : (Date.now() * Math.random()).toString().replace('.', '')}`;

        panels.forEach((panel, index) => {           
            const button = panel.querySelector('.accordion-pannel__button');
            const pannelWrapper = panel.querySelector('.accordion-pannel__wrapper');           
            if(!button.hasAttribute('aria-expanded')) {
                button.setAttribute('aria-expanded', false);
            }
            const id = `${baseId}_${index}`;
            button.setAttribute('aria-controls', id);
            pannelWrapper.id = id;
            function handleClick(button,e) {
                const isOpen = button.getAttribute('aria-expanded')?.toLowerCase() === 'true';
                button.setAttribute('aria-expanded', !isOpen);               
            };
            button.addEventListener('click', handleClick.bind(this, button));
        });
    });
}