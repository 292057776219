import {getRequest} from "../general/xhr-requests"

if ($('.add-to-cart-btn').length) {


    window.webShopConfig = {
        maxCountPerLineItem: 10000
    };

    var cartId = getCartId();
    var market = window.wiloconfig.market;
    var baseUrl = getBaseUrl();
    

    function getLocalId() {
        return window.wiloconfig.country.locale;
    }

    var requestCounter = 0;

    // Get cartid or generate new one
    function getCartId() {
        var id = localStorage.getItem('wilo:' + market + '/cart');
        if (id === null || undefined || '') {
            getRequest('/api/' + getLocalId() + '/cart', function (response, xhr) {
                if (xhr.status !== 200) {
                    requestCounter++;
                    if (requestCounter <= 5) {
                        getCartId();
                    } else {
                        console.log('maximum call count reached');
                    }
                } else {
                    var count = JSON.parse(response).maxLineItems;
                    if (count !== undefined || count !== null || count !== '') {
                        window.webShopConfig.maxCountPerLineItem = count;
                    }
                    cartId = JSON.parse(response).id;
                    localStorage.setItem('wilo:' + market + '/cart', cartId);
                    localStorage.setItem('cartCount', response.count);
                    $('.cart-count').text(response.count);
                    requestCounter = 0;
                }
            });
        } else {
            getCartById(id);
        }
        return id;
    }

    // Get Cart by ID
    function getCartById(id) {
        var cartId = id;
        getRequest('/api/' + getLocalId() + '/cart/' + id, function (response, xhr) {
            if (xhr.status !== 200) {
                localStorage.removeItem('wilo:' + market + '/cart');
                requestCounter++;
                if (requestCounter <= 5) {
                    getCartId();
                } else {
                    console.log('maximum call count reached');
                }
            } else {
                var count = JSON.parse(response).maxLineItems;
                if (count !== undefined || count !== null || count !== '') {
                    window.webShopConfig.maxCountPerLineItem = count;
                }
                checkCartCount(response);
                requestCounter = 0;
            }
        });
        return cartId;
    }

}

$('.cart-count').text(localStorage.getItem('cartCount'));
checkCartCount();

// Set count of cart items in navigation badge
function checkCartCount(cart) {
    var badge = $('.cart-count');
    var count = 0;
    if (cart) {
        count = cart.count;
    } else {
        count = localStorage.getItem('cartCount');
        if (isNaN(count) || !count) {
            count = 0;
        }
    }
    if (count > 99) {
        badge.text('99+');
    } else {
        badge.text(count);
    }
    localStorage.setItem('cartCount', count);
}

export function getBaseUrl() {
    var location = window.location;
    var currUrl = location.protocol + '//' + location.host;
    if (currUrl !== 'http://localhost:9000' && currUrl !== 'http://localhost:4200') {
        return currUrl + '/services';
    } else {
        return 'http://localhost:8080';
    }
}
