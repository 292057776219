import "select2"

jQuery(function($) {
    
    function initFormElements() {
        // select2 dropdowns
        $('select.mwf-select__select:not(.unstyled-form-control)').select2({
            width: '100%',
            minimumResultsForSearch: Infinity
        });
    }
    document.addEventListener("mwf-ajax-finished", function(event) {
        initFormElements();
    });
    initFormElements();
});
