// CountUp
function animateValue(elem, config) {
  var end = parseFloat(elem.textContent);
  Math.easeOutQuad = function (t, b, c, d) {
    t /= d;
    return -c * t * (t - 2) + b;
  };

  function initCountUpConfig(config) {
    if (!config) {
      config = {};
    }
    return {
      duration: config.duration ? config.duration : 1000,
      startAt: config.startAt ? config.startAt : 0,
      stepSpeed: config.stepSpeed ? config.stepSpeed : 50,
    };
  }

  function renderOutput(value) {
    var lang = document.documentElement.lang;
    var val = value + "";
    if (lang === "" || lang === "en-GB") {
      return formatNumber(val, ",", ".");
    } else {
      return formatNumber(val, ".", ",");
    }
  }

  function formatNumber(val, symbol1, symbol2) {
    var numbers = val.split(".");
    var output = "";
    if (numbers[0].length > 3) {
      var decimals = numbers[0].split("");
      for (var i = 1; i <= Math.floor(decimals.length / 3); i++) {
        var currNumber = decimals.length - i * 3 - 1;
        decimals[currNumber] += symbol1;
      }
      for (var i = 0; i < decimals.length; i++) {
        output += decimals[i];
      }
    } else {
      output += numbers[0];
    }
    if (numbers[1]) {
      output += symbol2 + numbers[1];
    }
    return output;
  }

  if (!isNaN(end) && !hasClass(elem, "animate")) {
    addClass(elem, "animate");
    var elemConfig = initCountUpConfig(config),
      start = elemConfig.startAt,
      range = end - start,
      current = start,
      stepSpeed = elemConfig.stepSpeed,
      time = 0,
      decimalsCount = (function (value) {
        if (Math.floor(value) !== value)
          return value.toString().split(".")[1].length || 0;
        return 0;
      })(end),
      stepSize = 0.1,
      timer = setInterval(function () {
        if (end > current) {
          current =
            parseFloat(current) +
            Math.easeOutQuad(time, start, range, elemConfig.duration * 100);
          current = current.toFixed(decimalsCount);
        }
        if (end < current) {
          current = end;
        }
        time += stepSpeed;
        elem.innerHTML = renderOutput(current);
        if (current == end) {
          clearInterval(timer);
        }
      }, stepSpeed);
  }
}

function checkViewPort(elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

function getElements(selector, context) {
  return (context || document).querySelectorAll(selector);
}

function hasClass(elem, className) {
  if (!elem) return;
  return elem.classList
    ? elem.classList.contains(className)
    : new RegExp("\\b" + className + "\\b").test(elem.className);
}

function addClass(elem, className) {
  if (!elem) return;
  if (elem.classList) elem.classList.add(className);
  else if (!hasClass(elem, className)) elem.className += " " + className;
}

window.addEventListener("scroll", function () {
  getElements(".count-up").forEach(function (elem) {
    if (checkViewPort(elem)) {
      animateValue(elem);
    }
  });
});
