import {postRequest} from "../general/xhr-requests"

// Add pump to cart
$('body').on('click', '.add-to-cart-btn', function () {
    var count = parseInt($(this).parents('tr, .atcBox').find('input').val());

    if (count > 0) {
        var quantity = JSON.stringify({
            'count': count
        });
        postRequest('/api/' + window.wiloconfig.country.locale + '/cart/' + cartId + '/lineitem?sku=' + $(this).data('sku'), quantity, function (data, xhr) {
            var cart = JSON.parse(data);
            localStorage.setItem('cartCount', cart.count);
            $('.cart-count').text(cart.count);
            // Never use jQuery !!!
            if (cart.error !== undefined) {
                if (cart.error === 'eCommerce.errors.maxLineItems') {
                    showOffCanvasLayer();
                }
            }
            if (xhr.status === 404) {
                console.log('Ups, da ist etwas schiefgelaufen. Bitte versuchen sie es später erneut.');
            }
            const pageBody = document.querySelector('body');
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': pageBody.offsetTop
            });
        });
    }

});

// Toggle Success on Button
$('.add-to-cart-btn > .wilo-btn').click(function () {
    var elem = $(this);
    elem.addClass('wilo-btn-check');
    setTimeout(function () {
        elem.removeClass('wilo-btn-check');
    }, 2000);
});
