import {closeOffCanvasLayer} from "./main"
import {showOffCanvas} from "./general/offCanvas"

'use strict';
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define("rttHandlerApi", factory);
    } else {
        // Browser globals
        root.rttHandlerApi = factory();
    }
}(window, function () {
    return {
        init: function () {
            // nothing to do for now
        },
        /**
         * Handles the given country and  call {@link #handleLocation} if a
         * `locationTargetingIsEnabled` is enabled in rtt config
         *
         * @param country The ISO 3166‑1 country code to handle
         */
        hookForRTT: function (country) {
            console.log('given country: ' + country);
            if (
                country &&
                window.wiloconfig.rtt &&
                window.wiloconfig.rtt.locationTargetingIsEnabled === true
            ) {
                this.handleLocation(country);
            }
        },
        /**
         * Location based retargeting
         **/
        alternateLocationsStorageKey: 'wilo-alternate-locations-',
        lastVisitedCountrySiteStorageKey: 'wilo-last-visited-country-site',
        disableRttOffCanvasKey: 'wilo-disable-rtt-',
        handleLocation: function (locationSegment) {
            const currentCountry = (window).wiloconfig.country.country;
            const currentLocation = locationSegment.toString().replace('v_location_segment_', '');
            const teaserElement = document.getElementById('location-off-canvas-template');

            // Save current county site for later actions
            this.handleLastVisitedCountrySite(currentCountry);

            // Check of offCanvas is available
            if (teaserElement !== undefined && teaserElement !== null && !this.checkForDisabledRtt(currentCountry, currentLocation)) {
                // Check if current location match current country site
                if (currentLocation !== currentCountry) {
                    // Check if an alternate location was all ready set
                    if (!this.checkForAlternateLocation(currentLocation, currentCountry)) {
                        // Show alternate location teaser in offCanvas
                        this.showAlternateCountrySiteTeaser(currentLocation, currentCountry);
                    } else if (window.wiloconfig.rtt.previewMode !== true) {
                        window.location.href = window.wiloRttConfig.urls[currentLocation.toString().toLowerCase()];
                    }
                }
            }
        },
        handleLastVisitedCountrySite: function (currentCountry) {
            // Save current country site
            localStorage.removeItem(this.lastVisitedCountrySiteStorageKey);
            localStorage.setItem(this.lastVisitedCountrySiteStorageKey, currentCountry.toUpperCase());
        },
        handleDisableRttOffCanvas: function (currentCountry, currentLocation) {
            localStorage.setItem(this.disableRttOffCanvasKey + currentCountry.toUpperCase(), currentLocation.toUpperCase());
        },
        showAlternateCountrySiteTeaser: function (currentLocation, currentCountry) {
            const locationTeaserTemplate = document.getElementById('location-off-canvas-template');
            const translations = this.getTranslations();
            showOffCanvas(locationTeaserTemplate);
            locationTeaserTemplate.style.display = 'flex';
            document.querySelector('.locationRetargeting__text').innerText = translations.text.replace('{x}', currentCountry.toUpperCase());
            document.querySelector('.locationRetargeting__flag').src = window.wiloconfig.distResources + 'img/flags/' + currentLocation.toUpperCase() + '_Flag.svg';

            this.addVisitAlternateCountrySiteButton(currentLocation, currentCountry, translations);
            this.addOverviewButton(currentLocation, currentCountry, translations);
            this.bindDisableRttOffCanvas(currentCountry, currentLocation);
        },
        getTranslations: function () {
            let translations;
            // get browsers language
            let browserLang = navigator.language.slice(0, 2);
            if (browserLang === undefined || browserLang === null) {
                browserLang = navigator.userLanguage.slice(0, 2) || 'en';
            }
            // get translations from browser language
            translations = window.wiloRttConfig.labels[browserLang];

            return translations;
        },
        checkForAlternateLocation: function (currentLocation, currentCountry) {
            const alternateLocation = localStorage.getItem(this.alternateLocationsStorageKey + currentCountry.toString().toUpperCase());
            let alternateLocationExists = false;

            if (alternateLocation !== null) {
                if (alternateLocation !== currentCountry.toString().toUpperCase()) {
                    alternateLocationExists = true
                }
            }

            return alternateLocationExists;
        },
        checkForDisabledRtt: function (currentCountry, currentLocation) {
            const alternateLocation = localStorage.getItem(this.disableRttOffCanvasKey + currentCountry.toString().toUpperCase());
            return alternateLocation === currentLocation.toString().toUpperCase();
        },
        saveAlternateLocation: function (currentCountry, alternateLocation) {
            const alternateLocationKey = this.alternateLocationsStorageKey + currentCountry.toString().toUpperCase();
            localStorage.setItem(alternateLocationKey, alternateLocation.toString().toUpperCase());
        },
        addOverviewButton: function (currentLocation, currentCountry, translations) {
            const btnOverviewUrl = window.wiloRttConfig.urls['overview'];
            const overview = document.querySelector('.locationRetargeting__overview');
            const country = document.querySelector('.locationRetargeting__country');
            overview.href = btnOverviewUrl;
            country.innerText = window.wiloRttConfig.countries[currentLocation];
        },
        bindDisableRttOffCanvas: function (currentCountry, currentLocation) {
            const __this = this;
            const btn = document.querySelector('.locationRetargeting__close');
            btn.addEventListener('click', function (event) {
                event.preventDefault();
                __this.handleDisableRttOffCanvas(currentCountry, currentLocation);
            });
        },
        addVisitAlternateCountrySiteButton: function (currentLocation, currentCountry, translations) {
            const btn = document.querySelector('.locationRetargeting__link');
            const __this = this;
            btn.innerText = translations.btnCountrySite.replace('{x}', currentLocation.toUpperCase());
            btn.addEventListener('click', function (event) {
                event.preventDefault();
                // __this.saveAlternateLocation(currentCountry, currentLocation);
                closeOffCanvasLayer();
                window.location.href = window.wiloRttConfig.urls[currentLocation.toString().toLowerCase()];
            });

        }
    };
}));
