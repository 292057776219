var products = [{
                  "image": "http://placehold.it/1350x150",
                  "title": "Wilo-Stratos",
                  "subline": "The diverse one"
                },
                  {
                    "image": "http://placehold.it/350x1150",
                    "title": "Wilo-Yonos MAXO",
                    "subline": "The practical one"
                  },
                  {
                    "image": "resources/img/wilo-sifire-en.png",
                    "title": "Wilo-SiFire EN",
                    "subline": "The steady one"
                  },
                  {
                    "image": "resources/img/wilo-drainloft-con.png",
                    "title": "Wilo-DrainLift Con",
                    "subline": "The variable one"
                  },
                  {
                    "image": "resources/img/wilo-star-z-nova.png",
                    "title": "Wilo-Star Z NOVA",
                    "subline": "The diligent one"
                  },
                  {
                    "image": "resources/img/wilo-stratos-pico.png",
                    "title": "Wilo-Stratos PICO",
                    "subline": "The convenient one"
                  }];

if(typeof Handlebars !== "undefined") {
  var template = $('#template').html();
  var compile = Handlebars.compile(template);
  var result = compile(products);
  $('#content').html(result);
}