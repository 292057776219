export default function initBsrMainNavigation() {
    const NAVI_STICKY_CLASSNAME = 'main-navigation-wrapper--sticky';
    const NAVI_FOLDED_CLASSNAME = 'main-navigation-wrapper--folded';

    let previosScrollTop = 0;

    const debounce = (func, delay) => {
        let timeout;
        const debounced = (...args) => new Promise((resolve) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => resolve(func(...args), delay));
        })
        return debounced;
    }

    const canScrollTop = () => window.scrollY > 0;

    const handleScroll = () => {
        if(canScrollTop()) {
            naviElement.classList.add(NAVI_STICKY_CLASSNAME);   
        } else {
            naviElement.classList.remove(NAVI_STICKY_CLASSNAME);
        }

        if(previosScrollTop < window.scrollY) {
            naviElement.classList.add(NAVI_FOLDED_CLASSNAME);
        } else {
            naviElement.classList.remove(NAVI_FOLDED_CLASSNAME);
        }

        previosScrollTop = window.scrollY;
    };

    const naviElement = document.querySelector('.bsr .main-navigation-wrapper');

    if(naviElement) {
        window.addEventListener('scroll', debounce(handleScroll, 100));
    }
}