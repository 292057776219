const onIntersection = (element, entries, observer) => {  
    const isBelowViewPort = () => entries[0].boundingClientRect.top >= 0
    
    if(entries[0].isIntersecting) {
        element.classList.add('text-on-image--in-view');
    } else if(!entries[0].isIntersecting && isBelowViewPort()) {
        element.classList.remove('text-on-image--in-view');
    } else {
        element.classList.add('text-on-image--in-view');
    }
}

export function initTextOnImage() {   
    const elements = Array.from(document.querySelectorAll('.text-on-image') || []);
    elements.map((element) => {
        const observer = new IntersectionObserver(
            onIntersection.bind(this, element), 
            {
                threshold: 0.75,
            }
        );
        observer.observe(element);
    });
    
}