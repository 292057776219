//Fly to cart animation and change button style
$('.js-fly-to-cart').click(function () {
    var cartIcon = $('.icon-sales');
    var flyingImg = $(this).parents('.item-slide, .collapse-container-inner').find('img');

    /* $('html, body').animate({
        'scrollTop': $('#cart').position().top
    }, 'slow'); */

    console.log(flyingImg.length);

    if (flyingImg.length) {
        var imgClone = flyingImg.clone()
            .offset({
                top: Math.round(flyingImg.offset().top),
                left: Math.round(flyingImg.offset().left)
            })
            .css({
                'opacity': '0.7',
                'position': 'absolute',
                'height': flyingImg.height(),
                'width': flyingImg.width(),
                'z-index': '1000'
            })
            .appendTo($('body'))
            .animate({
                'top': cartIcon.offset().top + 10,
                'left': cartIcon.offset().left + 10,
                'width': 50,
                'height': 50,
                'z-index': '1200',
                'border-radius': '50%'
            }, 1000, 'easeInOutExpo')
            .animate({
                'width': 0,
                'height': 0
            }, function () {
                $(this).detach();
            });
    }
});
