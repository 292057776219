import "slick-carousel"

$(document).ready(function () {
    var nextArrow = '<div class="slider-arrow slider-arrow-dark-grey arrow-right"><span class="wilo-icon icon-arrow-right"></span></div>';
    var prevArrow = '<div class="slider-arrow slider-arrow-dark-grey arrow-left"><span class="wilo-icon icon-arrow-left"></span></div>';

    function initImageModule() {
        $('.image-module').each(function (_, elem) {
            if ($(this).find('.image-module-image-wrapper').length > 2) {
                var innerImageWrapper = $(this).find('.image-module-images-wrapper-inner');
                if(!innerImageWrapper.hasClass('slick-initialized')){
                    innerImageWrapper.slick({
                        dots: true,
                        infinite: true,
                        speed: 300,
                        slidesToShow: 1,
                        arrows: true,
                        prevArrow: prevArrow,
                        nextArrow: nextArrow,
                        swipeToSlide: true,
                        adaptiveHeight: true
                    });
                }   
            }
        });
    }

    initImageModule();

    window.addEventListener('onRouteChange', function() {
        initImageModule();
    });

    if ($('.stage-header').find('div').length > 1) {
        $('.stage-images').slick({
            lazyLoad: 'ondemand',
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            arrows: true,
            dots: true,
            prevArrow: prevArrow,
            nextArrow: nextArrow,
            accessibility: false,
            autoplay: true,
            autoplaySpeed: 4000,
            fade: true,
            cssEase: 'linear',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        dots: false,
                    }
                },
            ]
        });
    }

});
