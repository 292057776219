import "slick-carousel"

function initProductSeries(){

  var nextArrow =
  '<div class="slider-arrow slider-arrow-dark-grey arrow-right"><span class="wilo-icon icon-arrow-right"></span></div>';
  var prevArrow =
  '<div class="slider-arrow slider-arrow-dark-grey arrow-left"><span class="wilo-icon icon-arrow-left"></span></div>';

  function initPromoCarousel() {
    var carouselPromo = $('.carouselPromo');
    if(!carouselPromo.hasClass('slick-initialized')){
      carouselPromo.slick({
        nextArrow: nextArrow,
        prevArrow: prevArrow,
        dots: true
      });
    }
  };

  window.addEventListener('onRouteChange', function() {
    initPromoCarousel();
  });

  if ($('.product-series-page-wrapper').length) {

    initPromoCarousel();

    $('.init--productSlider').click(function() {
      initProductSlider();
    });

    if ($('.tab-pane.active').find('.product-images .thumbnail-image').length) {
      initProductSlider();
    }

    function initProductSlider() {
      setTimeout(function() {
        (function() {
          var thumbnailImages = $('.product-images .thumbnail-image');
          if (thumbnailImages.length < 5) {
            thumbnailImages.first().addClass('slick-current');
            thumbnailImages.on('click', function() {
              thumbnailImages.removeClass('slick-current');
              $(this).addClass('slick-current');
              $('.product-images .slider-images').slick(
                'slickGoTo',
                $(this).index()
              );
            });
          } else {
            $('.product-images .thumbnail-images').slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              arrows: false,
              mobileFirst: true,
              asNavFor: '.product-images .slider-images',
              focusOnSelect: true,
              responsive: [
                {
                  breakpoint: 769,
                  settings: {
                    vertical: true
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    vertical: false
                  }
                }
              ]
            });
          }
        })();

        $('.product-images .slider-images').on('beforeChange', function(
          event,
          slick,
          currentSlide,
          nextSlide
        ) {
          var iframe = slick.$slides[nextSlide].querySelector('iframe');
          if (iframe) {
            iframe.setAttribute('src', iframe.getAttribute('data-src'));
          }
        });

        $('.product-images .slider-images')
          .not('.slick-initialized')
          .slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            infinte: false,
            swipe: false
          });
      }, 1);
    }

    var $iframe = $('.iframe--mobile');
    var src = $iframe.data('src');

    function showIframe() {
      if (window.matchMedia('(min-width: 992px)').matches) {
        $iframe.attr('src', src);
      }
    }

    $(window).on('resize', showIframe);

    // Initialize it once on document ready
    showIframe();

    var lastwidth;

    $(window).resize(function() {
      toggleTabs();
    });

    function toggleTabs() {
      var width = $(window).width();
      if (window.innerWidth > 991) {
        if (width > lastwidth) {
          if ($('.series-tabs li:hidden:first').length) {
            $('.tab-burger').removeClass('hidden');
            $('.series-tabs li:hidden:first').show();
            $('.tab-burger li:not(".hidden"):first').addClass('hidden');
            toggleTabs();
            hideOverflowingTabs();
          } else if (!$('.tab-burger').hasClass('hidden')) {
            $('.tab-burger').addClass('hidden');
          }
        } else {
          hideOverflowingTabs();
        }
      }
      lastwidth = width;
    }

    function hideOverflowingTabs() {
      var width = $(window).width();
      if (
        $('.series-tabs').height() > 58 ||
        (width < 768 && $('.series-tabs li:visible').length)
      ) {
        $('.tab-burger').removeClass('hidden');
        if ($('.tab-burger:hidden').length) {
          $('.tab-burger').show();
        }
        $('.series-tabs li:visible:last').hide();
        $('.tab-burger li.hidden:last').removeClass('hidden');
        toggleTabs();
      }
    }

    $(document).ready(function() {
      toggleTabs();
    var tabWrapper = $('tab-wrapper');
    if(tabWrapper.length){
        var tabMenuOffset = tabWrapper.offset().top;

        $(window).scroll(function() {
          var isFixed = $('.tab-wrapper').hasClass('fixed');
          if (tabMenuOffset > window.pageYOffset && isFixed) {
            $('.tab-wrapper').removeClass('fixed');
          } else if (tabMenuOffset < window.pageYOffset && !isFixed) {
            $('.tab-wrapper').addClass('fixed');
          }
        });
    }

      $('.activate-slick').each(function() {
        var tabSelector = $(this).attr('href');
        var tab = $(tabSelector);
        if (tab.find('.images img').length > 1) {
          $(this).click(function() {
            setTimeout(function() {
              var images = tab.find('.images');
              if (images.hasClass('slick-initialized')) {
                tab.find('.slick-slider').each(function() {
                  $(this).slick('refresh');
                });
              } else {
                tab.find('.images').slick({
                  arrows: true,
                  nextArrow:
                    '<div class="slider-arrow arrow-right"><span class="wilo-icon icon-arrow-right"></span></div>',
                  prevArrow:
                    '<div class="slider-arrow arrow-left"><span class="wilo-icon icon-arrow-left"></span></div>',
                  asNavFor: tabSelector + ' .thumbnails'
                });
                tab.find('.thumbnails').slick({
                  asNavFor: tabSelector + ' .images',
                  focusOnSelect: true,
                  slidesToShow: 4
                });
              }
            }, 100);
          });
        } else {
          tab.find('.thumbnails').addClass('hidden');
        }
      });
    });

    $('.tab-burger .tab-toggle').click(function() {
      $(this)
        .siblings('ul')
        .slideToggle();
    });

    $('.series-tabs li').click(function() {
      var index = $(this).index();
      $('.tab-burger .tab-toggle').removeClass('active');
      $($('.tab-burger li').removeClass('active')[index]).addClass('active');
      $('.tab-burger ul')
        .stop()
        .slideUp();
    });

    $('.tab-burger li').click(function() {
      var index = $(this).index();
      $('.tab-burger .tab-toggle').addClass('active');
      $($('.series-tabs li').removeClass('active')[index]).addClass('active');
      $('.tab-burger ul')
        .stop()
        .slideUp();
    });
  }
}

initProductSeries();

/// Responsive Productlist Table
document.addEventListener('DOMContentLoaded', event => {
  restructureTable();
});

window.addEventListener('resize', event => {
  restructureTable();
});

const restructureTable = () => {
  let screenWidth = screen.width;
  let productListTable = document.getElementById('productListTable');

  if (productListTable) {
    if (screenWidth <= 480) {
      productListTable.classList.remove('v-table-active-bp-xs');
      productListTable.classList.remove('v-table-active-bp-sm');
      productListTable.classList.remove('v-table-active-bp-md');
      productListTable.classList.add('v-table-active-bp-xxs');
    }

    if (screenWidth > 480 && screenWidth <= 550) {
      productListTable.classList.remove('v-table-active-bp-xxs');
      productListTable.classList.remove('v-table-active-bp-sm');
      productListTable.classList.remove('v-table-active-bp-md');
      productListTable.classList.add('v-table-active-bp-xs');
    }

    if (screenWidth > 550 && screenWidth <= 768) {
      productListTable.classList.remove('v-table-active-bp-xxs');
      productListTable.classList.remove('v-table-active-bp-xs');
      productListTable.classList.remove('v-table-active-bp-md');
      productListTable.classList.add('v-table-active-bp-sm');
    }

    if (screenWidth > 768 && screenWidth <= 992) {
      productListTable.classList.remove('v-table-active-bp-xxs');
      productListTable.classList.remove('v-table-active-bp-xs');
      productListTable.classList.remove('v-table-active-bp-sm');
      productListTable.classList.add('v-table-active-bp-md');
    }

    if (screenWidth > 992) {
      productListTable.classList.remove('v-table-active-bp-xxs');
      productListTable.classList.remove('v-table-active-bp-xs');
      productListTable.classList.remove('v-table-active-bp-sm');
      productListTable.classList.remove('v-table-active-bp-md');
    }
  }
};
