import "slick-carousel"

if (('product-detail-images').length > 1) {
    $('.product-detail-images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        swipe: false,
        adaptiveHeight: true,
        arrows: false
    });

    $('.product-detail-thumbnails').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.product-detail-images',
        focusOnSelect: true,
        prevArrow: '<div class="product-detail-thumbnails-arrow"><span class="wilo-icon icon-arrow-left"></span></div>',
        nextArrow: '<div class="product-detail-thumbnails-arrow"><span class="wilo-icon icon-arrow-right"></span></div>',
        responsive: [{
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }]
    });
}
