import "select2";

const blogItems = $("[data-filter-category]");

$(function () {
  $("#blogFilter").select2({
    width: '100%',
  });
  $("#blogFilter").on("select2:select", function (e) {
    filter(e.params.data.id);
  });
});

/**
 * Shows and hides blog entries which are matches the filter category
 *
 * @param  {string} Value
 */
const filter = (value) => {
  blogItems.each(function () {
    if (
      value === "all" ||
      this.getAttribute("data-filter-category") === value
    ) {
      this.style.display = "block";
    } else {
      this.style.display = "none";
    }
  });
};
