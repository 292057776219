import {is_jquery_here} from "./inline_js"

var maxPumps = 250;
//var serviceUrl = '/exchangemirror/exchangemirror';
var serviceUrl = '/exchangemirror/exchangemirror';


var country = (wiloconfig.country.country || 'gb')
var lang = (wiloconfig.country.lang || 'en')

var replacementGuide = window.wiloconfig.replacementguide;
var culture;

if (replacementGuide && replacementGuide.culture) {
    culture = replacementGuide.culture
} else {
    culture = 'en-com';
}

// list of all pumps found
var pumpList;

//pageparts
var emailForm = jQuery('.em-container').parent().find('.webforms-form');
emailForm.addClass('emhidden');
var emailFormTemplate = emailForm.clone();
var progressLoader = jQuery('#progress-loading');

var searchExpressions = [];
var oldPumpObj = {};

//event handler
jQuery('#exchangemirror-search-button').click(function () {
    'use strict';
    showList();
});

//Enter function on inputfield
jQuery('#exchangemirror-search-input').keypress(function (e) {
    'use strict';
    if (e.keyCode === 13) {
        jQuery('#exchangemirror-search-button').click();
    }
});

/*+----------+
  | listview |
  +----------+*/
var listUrl;

// Show list of all found pumps
function showList() {
    'use strict';
    var listSearchExpr = jQuery('#exchangemirror-search-input').val();

    if (listSearchExpr && listSearchExpr.trim !== '') {
        jQuery('#em-error-msg-internal').hide();
        jQuery('#em-error-msg-noresults').hide();
        pumpList = [];
        emailForm.hide();
        jQuery('#exchangemirror-table').remove();
        progressLoader.show();

        var listUrl = serviceUrl + '?culture=' + culture + '&searchExpr=' + listSearchExpr + '&maxPumps=' + maxPumps;

        jQuery('#em-pump-list').html('');
        jQuery.ajax({
            url: listUrl
        }).done(function (data) {
            jQuery('#em-list-head').clone().appendTo('#em-pump-list').show();

            if (data.pumps.length > 0) {
                for (var i = 0; i < data.pumps.length; i++) {
                    addPumpToList(data.pumps[i], i);
                    pumpList.push(data.pumps[i]);
                }
                jQuery('#em-pump-list').show();
            } else {
                jQuery('#em-error-msg-noresults').show();
            }
        })
            .fail(function () {
                jQuery('#em-error-msg-internal').show();
            })
            .always(function () {
                progressLoader.hide();
            });
    }
}

/** Aligns the table cols to the same size */
function alignTableCols() {
    'use strict';
    var headers = jQuery('#exchangemirror-table th:visible');
    var headersLength = jQuery(headers).length;
    var percentWidth = 100 / headersLength + '%';

    jQuery(headers).each(function () {
        jQuery(this).css({
            'min-width': percentWidth,
            'max-width': percentWidth,
            'width': percentWidth
        });
    });
}

/** Adds a pump to the list
 * @param pump: the pump
 * @param index: index of the pump. Maps to the index
 *    in pumpList arry
 */
function addPumpToList(pump, index) {
    'use strict';
    var row = '<div class="col-md-5 pf-cell"><div class="pf-inner">' + pump.br + ' ' + pump.na + '</div></div>';
    row += '<div class="col-md-4 pf-cell"><div class="pf-inner">' + pump.dn + '</div></div>';
    row += '<div class="col-md-3 pf-cell"><div class="pf-inner">' + pump.le + 'mm</div></div>';

    var pumpID;
    if (pump.rp[0] === undefined) {
        pumpID = pump.id;
    } else {
        pumpID = pump.rp[0].id;
    }


    jQuery('<div />', {
        'class': 'row',
        html: row,
        click: function () {
            jQuery('#em-pump-list').hide();
            showDetail(index, pumpID, pump.id);
        }
    }).appendTo('#em-pump-list');
}

/*+------------+
  | Deatilview |
  +------------+*/

function showDetail(index, searchExpr, oldPumpID) {
    'use strict';

    if (searchExpr === undefined) {
        return;
    }

    // Set old pump id in search expr
    oldPumpObj.id = oldPumpID;
    searchExpressions.push(searchExpr);
    var detailUrl = serviceUrl + '/detail?culture=' + culture + '&searchExpr=' + searchExpr + '&oldPumpID=' + oldPumpID;
            
    jQuery.ajax({
        url: detailUrl,
        'success': function (data) {
            jQuery('#table-template').clone().appendTo('#em-table-target').attr('id', 'exchangemirror-table').show();
            var oldPump = pumpList[index];
            var alternativePump = pumpList[index].rp[1];

            for (var i = 0; i < data.length; i++) {
                addPump(data[i]);
            }

            
            addAlternativePumps(alternativePump, oldPump, oldPumpID);
            /*if (alternativePump !== undefined) {
                searchExpressions.push(alternativePump.id);
                addAlternativePump(alternativePump, '#standardPumpHeader', oldPumpID);
            }
            if (oldPump !== undefined) {
                oldPumpObj = oldPump;
                addAlternativePump(oldPump, '#oldPumpHeader', null);
                jQuery('#old-pump').val(oldPump.na);
            }*/
            jQuery('#em-search-string').html(pumpList[index].br + ' ' + pumpList[index].na);
            progressLoader.hide();
            alignTableCols();
        }
    });
}

function addAlternativePumps(alternativePump, oldPump, oldPumpID) {
    'use strict';
    
    function addOldPump(oldPump) {
        if (oldPump !== undefined) {
            oldPumpObj = oldPump;
            addAlternativePump(oldPump, '#oldPumpHeader', null);
            jQuery('#old-pump').val(oldPump.na);
        }
    }
    if (alternativePump !== undefined) {
        jQuery.ajax({
            url: serviceUrl + '/detail?culture=' + culture + '&searchExpr=' + alternativePump.id + '&oldPumpID=' + oldPumpID,
            'success': function (data) {
                searchExpressions.push(alternativePump.id);
                addAlternativePump(data[0], '#standardPumpHeader', true);
                addOldPump(oldPump);
            }
        });    
    } else {
        addOldPump(oldPump);
    }
    
}

// Go back to the list of pumps
function backToEMList() {
    'use strict';
    jQuery('#exchangemirror-table').remove();
    jQuery('#em-pump-list').show();
}

/** Adds a pump to the table
 * @param pump the pump that shall be added to the table
 */
function addPump(pump) {
    'use strict';

    var exchangeMirrorTable = jQuery('#exchangemirror-table');
    exchangeMirrorTable.find('table thead tr #standardPumpHeader').before('<th>' + pump.KategorieBezeichnung + '</th>');
    exchangeMirrorTable.find('#em-row-type').append('<td>' + pump.ArtBezeichnung + '</td>');
    exchangeMirrorTable.find('#em-row-engine').append('<td>' + pump.MotorAbbr + '</td>');
    exchangeMirrorTable.find('#em-row-art-no').append('<td>' + pump.Artikelnummer + '</td>');
    exchangeMirrorTable.find('#em-row-length').append('<td>' + pump.Baulaenge + 'mm' + '</td>');
    exchangeMirrorTable.find('#em-row-pn').append('<td>' + pump.PNText + '</td>');
    exchangeMirrorTable.find('#em-row-materials').append('<td>' + pump.MaterialBezeichnung + '</td>');
    exchangeMirrorTable.find('#em-row-range').append('<td>' + pump.DN + '</td>');
    exchangeMirrorTable.find('#em-row-name').append('<td>' + pump.Bezeichnung + '</td>');
    exchangeMirrorTable.find('#em-row-fitting-text').append('<td>' + pump.FittingText + '</td>');

    var productId = pump.Artikelnummer;
    var datasheetButton = jQuery('#datasheetbutton').clone();
    var productFinderUrl = 'https://wilo.com/quick/' + country + '/' + lang + '/articleNumber/' + productId;
    datasheetButton.attr('href', productFinderUrl);
    datasheetButton.removeClass('emhidden');

    var variousTableData = '';
    if (pump.LogoIds[0] === 1) {
        variousTableData += '<div class="ErPLogo" />';
    }
    jQuery('#exchangemirror-table #em-row-various').append('<td>' + datasheetButton.prop('outerHTML') + '<br>' + variousTableData + '</td>');
}

/** Add an alternative pump  to the table (the old and the standard pump)
 * @param: pump the pump that shall be added
 * @param: id: the id of the pump Header("oldPumpHeader" or "standardPumpHeader")
 */
function addAlternativePump(pump, id, isStandard) {
    function getPumpArtNo() {
        var pumpArtNo = '-';
        if (pump.ar !== undefined) {
            pumpArtNo = pump.ar;
        }
        return pumpArtNo;
    }
    
    function getPumpMaterial(pump) {
        var pumpMaterial = '-';
        if (pump.ma !== null) {
            pumpMaterial = pump.ma;
        }
        return pumpMaterial;
    }
    
    function getVarious(pumpArtNo) {      
        var various = '';
        if (pumpArtNo !== '-' && pumpArtNo != null) {
            var datasheetButton = jQuery('#datasheetbutton').clone();
            var productFinderUrl = 'https://wilo.com/quick/' + country + '/' + lang + '/articleNumber/' + pumpArtNo;
            datasheetButton.attr('href', productFinderUrl);
            datasheetButton.removeClass('emhidden');
            various = datasheetButton.prop('outerHTML');
            if (pump.LogoIds && pump.LogoIds[0] === 1) {
                various += '<div class="ErPLogo" />';
            }
        }
        return various;
    }
    
    if(isStandard === true){
        var exchangeMirrorTable = jQuery('#exchangemirror-table');
        exchangeMirrorTable.find('table thead').find(id).html(pump.KategorieBezeichnung);

        exchangeMirrorTable.find('table thead').find(id).show();
        exchangeMirrorTable.find('#em-row-type').append('<td>' + pump.ArtBezeichnung + '</td>');
        exchangeMirrorTable.find('#em-row-engine').append('<td>' + pump.MotorAbbr + '</td>');
        exchangeMirrorTable.find('#em-row-art-no').append('<td>' + pump.Artikelnummer + '</td>');
        exchangeMirrorTable.find('#em-row-length').append('<td>' + pump.Baulaenge + 'mm' + '</td>');
        exchangeMirrorTable.find('#em-row-pn').append('<td>' + pump.PNText + '</td>');
        exchangeMirrorTable.find('#em-row-materials').append('<td>' + pump.MaterialBezeichnung + '</td>');
        exchangeMirrorTable.find('#em-row-range').append('<td>' + pump.DN + '</td>');
        exchangeMirrorTable.find('#em-row-name').append('<td>' + pump.Bezeichnung + '</td>');
        exchangeMirrorTable.find('#em-row-fitting-text').append('<td>' +pump.FittingText + '</td>');
        exchangeMirrorTable.find('#em-row-various').append('<td>' + getVarious(pump.Artikelnummer) + '</td>'); 
    } else {
        var exchangeMirrorTable = jQuery('#exchangemirror-table');
        var punpArtNo= getPumpArtNo();
        exchangeMirrorTable.find('table thead').find(id).show();
        exchangeMirrorTable.find('#em-row-type').append('<td>' + pump.ty + '</td>');
        exchangeMirrorTable.find('#em-row-engine').append('<td>' + pump.mo + '</td>');
        exchangeMirrorTable.find('#em-row-art-no').append('<td>' + punpArtNo + '</td>');
        exchangeMirrorTable.find('#em-row-length').append('<td>' + pump.le + 'mm' + '</td>');
        exchangeMirrorTable.find('#em-row-pn').append('<td>' + pump.pn + '</td>');
        exchangeMirrorTable.find('#em-row-materials').append('<td>' + getPumpMaterial(pump) + '</td>');
        exchangeMirrorTable.find('#em-row-range').append('<td>' + pump.dn + '</td>');
        exchangeMirrorTable.find('#em-row-name').append('<td>' + pump.na + '</td>');
        exchangeMirrorTable.find('#em-row-fitting-text').append('<td>' + '-' + '</td>');
        exchangeMirrorTable.find('#em-row-various').append('<td>' + getVarious(punpArtNo) + '</td>');
    }
 
}

// Show the email form
function showEmail() {
    'use strict';
    jQuery('#exchangemirror-table').hide();
    jQuery('#wilo-em-search-box').hide();
    emailForm.prepend('<a class="wilo-btn wilo-btn-primary em-back-to-list" onclick="backToEMDetail();"></a>');
    emailForm.find('.em-back-to-list').text(jQuery('#exchangemirror-table .em-back-button').text());
    emailForm.show();
    emailForm.find('input[name=searchExpr]').val(JSON.stringify(searchExpressions));
    emailForm.find('input[name=culture]').val(culture);
    emailForm.find('input[name=oldPump]').val(JSON.stringify(oldPumpObj));
}

// Go back to the detail view
function backToEMDetail() {
    'use strict';
    jQuery('#wilo-em-search-box').show();
    jQuery('#exchangemirror-table').show();
    var parent = emailForm.parent();
    emailForm.replaceWith(emailFormTemplate.clone());
    emailForm = parent.find('.webforms-form');
    is_jquery_here();
    searchExpressions = [];
    oldPumpObj = {};
    emailForm.hide();
}

jQuery(document).ready(function () {
    'use strict';
    progressLoader.hide();
    jQuery('#wilo-em-search-box').show();

    var searchParam = location.search.split('replacement-guide-search=').splice(1).join('').split('‌​&')[0];
    if (searchParam && searchParam.trim() !== '') {
        jQuery('#wilo-em-search-box input').val(searchParam);
        jQuery('#exchangemirror-search-button').click();
    }

    //Teaser link to replacement-guide page
    jQuery('a#pump-replacement-teaser-button').click(function (e) {
        e.preventDefault();
        this.href += (this.href.split('?')[1] ? '&' : '?') + 'replacement-guide-search=' + jQuery(this).prev('.form-control').val();
        window.location = this.href;
    });

    jQuery('.pump-replacement-teaser-module input.form-control').keypress(function (e) {
        if (e.keyCode === 13) {
            jQuery('a#pump-replacement-teaser-button').click();
        }
    });

    $('.replacmentGuide__search').keypress(function(e) {
        if (e.keyCode === 13) {
            $('.replacmentGuide__form').submit();
        }
    });
});
