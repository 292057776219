import * as faultCodes from "../../media/fault_codes/index.js";

export default function initBSRFaultSignalAssistance() {
  const language = wiloconfig.country.lang.toUpperCase();
  const faultCodesLang = faultCodes[language];
  const faultCodeUnknown = faultCodesLang
    ? faultCodesLang[0].description
    : window.wiloconfig.faultSignalAssistance.labels.noLanguageSupport;

  const elements = document.querySelectorAll(".bsr-fault-signal-assistance");
  elements.forEach((element) => {
    const form = element.querySelector(".bsr-fault-signal-assistance__form");
    const resultsDiv = element.querySelector(
      ".bsr-fault-signal-assistance__results"
    );

    const buildResultEntry = (result) => {
      if (result.errorcode && result.errorcode != "Unknown") {
        return /*html*/ `
                <p>
                    <span class="bsr-fault-signal-assistance__errorcode">${
                      result.errorcode
                    }</span>
                    <span>${result.description}</span>
                </p>
                ${
                  result.cause
                    ? `<p>${window.wiloconfig.faultSignalAssistance.labels.cause}: ${result.cause}</p>`
                    : ""
                }
                ${
                  result.corrective
                    ? `<p>${window.wiloconfig.faultSignalAssistance.labels.corrective}: ${result.corrective}</p>`
                    : ""
                }
                ${
                  result.service
                    ? `<p>${window.wiloconfig.faultSignalAssistance.labels.service}: ${result.service}</p>`
                    : ""
                }
            `;
      } else {
        return ``;
      }
    };

    const buildErrorMessage = () => {
      return /*html*/ `
            <p>${faultCodeUnknown}</p>
        `;
    };

    form.onsubmit = (e) => {
      e.preventDefault();
      resultsDiv.innerHTML = "";

      const formData = new FormData(form);
      const searchTerm = formData.get("searchTerm")?.toLowerCase().trim();

      const results = faultCodesLang?.filter(
        (item) =>
          item.errorcode?.toLowerCase().trim().includes(searchTerm) ||
          item.description?.toLowerCase().trim().includes(searchTerm)
      );

      if (results && results.length > 0) {
        results.forEach((item) => {
          const element = document.createElement("div");
          element.classList.add("bsr-fault-signal-assistance__result");
          element.innerHTML = buildResultEntry(item);
          requestAnimationFrame(() => {
            resultsDiv.appendChild(element);
          });
        });
      } else {
        const element = document.createElement("div");
        element.innerHTML = buildErrorMessage();
        requestAnimationFrame(() => {
          resultsDiv.appendChild(element);
        });
      }
    };

    form.onreset = (e) => {
      resultsDiv.innerHTML = "";
    };
  });
}
