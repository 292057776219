$(() => {
    // check url for parameter ?maav=1
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const appParam = urlParams.get('maav');

    if(appParam && appParam === '1'){
        // if app, remove header and footer
        const header = $('#navigation');
        if(header) {
            $(header).remove();
        }
        const footer = $('footer');
        if(footer) {
            $(footer).remove();
        }
        const flyout = $('div.flyout');
        if(flyout) {
            $(flyout).remove();
        }
        const breadcrumb = $('ol.breadcrumb');
        if(breadcrumb) {
            $(breadcrumb).remove();
        }
    }
});