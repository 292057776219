document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('[data-cad-catalogue-searchstring]')){
    
        document.querySelector('[data-cad-catalogue-searchstring]').addEventListener("click", function () {
            getCADCatalogue(this);
        });

        function getCADCatalogue(elem) {
            var catalogueString = elem.getAttribute('data-cad-catalogue-searchstring');
            console.log(catalogueString);

            var http = new XMLHttpRequest();
            var url = "http://wilo.cadprofi.com/Search/" + catalogueString;
            http.open("POST", url, true);
            http.send();
        }
    }
}, false);