import {getBaseUrl} from "../ecommerce/e-commerce"
import {getCookie} from "./cookies"

// GET-XHR-Request
export function getRequest(url, done) {
    doRequest('GET', url, '', done);
}

// POST-XHR-Request
export function postRequest(url, data, done, withoutBaseUrl) {
    doRequest('POST', url, data, done, withoutBaseUrl);
}

// General-XHR-Request
function doRequest(type, url, data, done, withoutBaseUrl) {
    var xhr = new XMLHttpRequest();
    var baseUrl = getBaseUrl();
    if(withoutBaseUrl){
        xhr.open(type, url);
    }else{
        xhr.open(type, baseUrl + url);
    }
    xhr.setRequestHeader('X-XSRF-Token', getCookie('XSRF-TOKEN'));
    xhr.setRequestHeader("Content-type", "application/json");
    if (done) {
        xhr.onload = function () {
            done(xhr.response, xhr);
        }
    }
    xhr.send(data);
}
