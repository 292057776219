function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

(function initBlog() {

    var categoryId = getParameterByName('categoryId') || '';
    var blogTeasers = document.querySelectorAll('[data-category-id]');
    var filters = document.querySelectorAll('.blog__filter');
    if (categoryId) {
        parseInt(categoryId);
    }
    var active = document.querySelector('[data-category-filter-id="' + categoryId + '"]');
    var ac = 'blog__filter--active';

    if(active) {
        active.classList.add(ac);
    }

    filterBlogEntries(categoryId);

    function filterBlogEntries(filter) {
        for (var i = 0; i < blogTeasers.length; i++) {
            var elem = blogTeasers[i];
            if (filter && !(elem.getAttribute('data-category-id') == filter)) {
                elem.classList.add('hidden');
            } else {
                elem.classList.remove('hidden');
            }
        }
    }

    for (var i = 0; i < filters.length; i++) {
        filters[i].addEventListener('click', function () {
            var activeElem = document.querySelector('.' + ac);
            if(activeElem) {
                activeElem.classList.remove(ac);
            }
            this.classList.add(ac);
            filterBlogEntries(this.getAttribute('data-category-filter-id'));
        });
    }
})()