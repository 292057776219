$(document).ready(function () {
    let filterLocationData = function () {
        let selectedPartner = document.getElementById('partner-select').value;
        let selectedCategory = document.getElementById('category-select').value;

        Object.keys(wiloLocatorData.data).forEach(function (index) {
            if ((selectedPartner === '0' || selectedPartner === window.wiloLocatorData.data[index].partner ) && (selectedCategory === '0' || selectedCategory === window.wiloLocatorData.data[index].category)) {
                window.wiloLocatorData.data[index].display = true;
            } else {
                window.wiloLocatorData.data[index].display = false;
            }
        });

        locatoModule.$refs['maps-app'].removeAllMarkers();
        locatoModule.$refs['maps-app'].initMarkers();
        locatoModule.$refs['maps-app'].initCluster();
    };

    $('#category-select').change(function() {
        filterLocationData();
    });

    $('#partner-select').change(function() {
        filterLocationData();
    });
});