import { deleteCookie, getCookie } from "./general/cookies";

export default function initLoginButton() {
    const COOKIE_NAME = 'website_user_id';
    const LOGGED_IN_ATTRIBUTE_VALUE ='logged-in';
    const LOGGED_OUT_ATTRIBUTE_VALUE ='logged-out';
    const loginUrl = wiloconfig.country.login?.loginUrl ;
    const logoutUrl = wiloconfig.country.login?.logoutUrl;

    function setLoginState(form, button, isLoggedIn) {
        if(isLoggedIn) {
            button.dataset.state = LOGGED_IN_ATTRIBUTE_VALUE;
            form.action = logoutUrl;
            
        } else {
            button.dataset.state = LOGGED_OUT_ATTRIBUTE_VALUE;
            form.action = loginUrl;
        }
    }

    const forms = document.querySelectorAll('.loginForm');
    
    
    if(forms.length) {
        const cookie = getCookie(COOKIE_NAME);
        forms.forEach((formElement) => {
            const button = formElement.querySelector('button');
            setLoginState(formElement, button, !!cookie);
        });

        if(!!cookie) {
            const interval = setInterval(() => {
                const currentCookie = getCookie(COOKIE_NAME);
                const parsedCookie = JSON.parse(decodeURIComponent(currentCookie));
                if(new Date().getTime() >= parsedCookie.ext * 1000) {
                    forms.forEach((element) => {
                        setLoginState(element, false);
                    });
                    deleteCookie(COOKIE_NAME);
                    clearInterval(interval);
                }
            }, 3000);
        }
    }
}