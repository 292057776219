var initGlossary = function () {

    var alphabetAllSelector = wiloconfig.GENERAL_TRANSLATION_KEYS.glossary.allButton.toLowerCase() || 'all';

    function filterGlossary(item, letter) {
        return $(item).children('span').text().toLowerCase() === letter;
    }

    //Toggle Aktive Letters
    function initActiveLetters() {
        $('.alphabet-letters').find('li.alphabet-letter').each(function () {
            var letter = $(this).text().toLowerCase();
            var wanted = $.grep($('.glossary-letter-wrap'), function (item) {
                return filterGlossary(item, letter);
            });
            if (wanted.length === 0 && letter !== alphabetAllSelector) {
                $(this).addClass('no-content');
            }
        });
    }

    var generateAlphabeticFilter = function() {
        var alphabet = [alphabetAllSelector.toUpperCase() , 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'];
        $.each(alphabet, function (letter) {
            $('.alphabet-letters').append($('<li class="alphabet-letter transition-all">' + alphabet[letter] + '</li>'));
        });
        initActiveLetters();
    };

    //Filtern nach dem ausgewaehltem Buchstaben
    function toggleLetter(sortkey) {
        $('.glossary-letter-wrap').each(function () {
            if (sortkey === alphabetAllSelector) {
                $(this).show();
            } else {
                var letter = $(this).children('span').text().toLowerCase();
                letter === sortkey ? $(this).show() : $(this).hide();
            }
        });

    }

    $('.alphabetic-filter-index').on('click', '.alphabet-letter:not(".no-content")', function () {
        $('.alphabet-letter').removeClass('active');
        $(this).addClass('active');
        toggleLetter($(this).text().toLowerCase());
    });

    generateAlphabeticFilter();

};

initGlossary();