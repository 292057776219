import flatpickr from "flatpickr";

export function initEcolutionForm() {
    const dateInputs = [
        document.querySelector('.ecolution [name="beginn"]'),
        document.querySelector('.ecolution [name="ende"]')
    ]

    dateInputs.forEach((element) => {
        flatpickr(element, {dateFormat: "d.m.Y"});
    });
}