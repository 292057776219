$(document).ready(function(){
    $('.references-count').text($('.references-item').length);
});

$('.references-filter select').change(function () {
    filterReferences()
    $('.references-count').text($('.references-item:visible').length);
});

function filterReferences(){
    var ids = ['country', 'app-segment', 'series']
    var vals = [$('.references-filter select#country').val(), $('.references-filter select#app-segment').val(), $('.references-filter select#series').val()];
    $('.references-item').each(function () {
        $(this).show();
        for (var i = 0; i < vals.length; i++) {
            if (vals[i] === 'all') {
                continue;
            } else {
                if (!$(this).attr('data-' + ids[i]).includes(vals[i])) {
                    $(this).hide();
                    continue;
                }
            }
        }
    });
}