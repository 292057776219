/**
 * Creates a dropdown for tab navigations (.nav.nav-tabs) when you add the css class "tab-dropdown-mode".
 * You can configure when the dropdown should be visible with a data attribute
 *
 * <ul class="nav nav-tabs" data-show-dropdown="xs,sm">
 *  ...
 * </ul>
 *
 * The data attribute is optional. When you don't provide the data attribute, the default value is "xs".
 */
 $.fn.tabify = function() {
   return this.each(function() {

     var _this = $(this);
     var showWhen = $(this).data('showDropdown') || ['xs'];
     var tabNavElem = $(this);
     if (typeof showWhen === 'string') {
         showWhen = showWhen.split(',');
     }

     var select = $(document.createElement('select'))
         .addClass('tab-dropdown');

     showWhen.forEach(function (size) {
         select.addClass('visible-' + size);
     })


     tabNavElem.find('> li a[data-toggle="tab"]').each(function (_, link) {
         var linkElem = $(link);
         var option = $(document.createElement('option'));
         option.attr('value', linkElem.attr('href'));
         option.text(linkElem.text());
         if (linkElem.parent().hasClass('active')) {
             option.attr('selected', 'selected');
         }
         select.append(option);
     });

     var hideClasses = ['xs', 'sm', 'md', 'lg'];
     hideClasses.forEach(function (clazz) {
         if (showWhen.indexOf(clazz) === -1) {
             select.addClass('hidden-' + clazz);
         } else {
             tabNavElem.addClass('hidden-' + clazz);
         }
     });


     select.on('change', function (change) {
         var newTab = $(this).val();
         // trigger bootstrap link when use selects a new option
         $(_this).find('li a[href="' + newTab + '"]').click();
     });
     select.insertAfter(this);

     select.wrap('<div class="styled-form visible-xs hidden-sm hidden-md hidden-lg"></div>');
     $('.styled-form select:not(.unstyled-form-control)').select2({
         width: '100%',
         minimumResultsForSearch: Infinity
     });
   });
 }
$(document).ready(function () {
    $('.nav.nav-tabs.tab-dropdown-mode').tabify();
});
