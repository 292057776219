import "./rttHandler"
import axios from "axios"

'use strict';
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['rttHandlerApi', 'wiloconfig'], factory);
    } else {
        if (typeof root.rttHandlerApi !== 'object' || typeof root.wiloconfig !== 'object') {
            if (typeof root.rttHandlerApi !== 'object') {
                console.log('rttHandlerApi not defined');
            } else {
                // fallback if config is missing
                let wiloconfig = {
                    rtt: {
                        previewMode: true
                    }
                };
                factory(
                    root.rttHandlerApi,
                    wiloconfig);
            }
            if (typeof root.wiloconfig !== 'object') {
                console.log("wiloconfig not defined");
            }
        } else {
            // Browser globals
            factory(
                root.rttHandlerApi,
                root.wiloconfig);
        }
    }
}(window, function (rttHandlerApi, wiloconfig) {
    const wiloRTTBridge = {
        init: function () {
            rttHandlerApi.init();
            if (wiloconfig.rtt.previewMode) {
                console.log('RTT in preview-mode.');
                let queryParams = {};
                location.search.substr(1).split('&').forEach(function (item) {
                    const key = item.split('=')[0],
                        value = decodeURIComponent(item.split('=')[1]);
                    (key in queryParams) ? queryParams[key].push(value) : queryParams[key] = [value]
                });
                if ('country' in queryParams) {
                    rttHandlerApi.hookForRTT(queryParams.country);
                } else {
                    console.log('RTT in preview-mode, but no segments to fake given (rtt_segment), reverting to normal mode');

                    this.setupRTT();
                }
            } else {
                this.setupRTT();
            }
        },
        setupRTT: function () {
            if (!wiloconfig.rtt || !wiloconfig.rtt.endpointUrl) {
                console.warn('wiloconfig.rtt or wiloconfig.rtt.endpointUrl not defined');

                return;
            }

            axios.get(wiloconfig.rtt.endpointUrl).catch(function(error) {
                console.error('got non-ok response from country detection api', error);
            }).then(function(response) {
                if (!response.data || !response.data.iso_code) {
                    console.error("country or the iso_code is not defined.");

                    return;
                }
                rttHandlerApi.hookForRTT(response.data.iso_code);
            });
        },
    };
    wiloRTTBridge.init();
}));
