document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('[data-pfinder-pdf-for]')) {
		
        document.querySelector('[data-pfinder-pdf-for]').addEventListener("click", function () {
            showPdfForm(this);
        });

        document.querySelector('.generate-pdf-form-wrapper .icon-close').addEventListener("click", function () {
            showPdfForm(this);
        });

        function showPdfForm(elem) {
            var tabHTML = '';
            var tabsElement = document.getElementsByClassName('pdftabs')[0];
            document.getElementsByClassName('generate-pdf-form-wrapper')[0].classList.toggle('active');
            document.getElementsByClassName('overlay')[0].classList.toggle('active');
            while (tabsElement.firstChild) {
                tabsElement.removeChild(tabsElement.firstChild);
            }
            JSON.parse(elem.getAttribute('data-pfinder-pdf-tabs')).forEach(function (e, i) {
                tabsElement.insertAdjacentHTML('beforeend', '<div class="col-md-6 col-sm-6 col-xs-12"><div class="checkbox-wrapper"><input class="pdfcheckbox" id="pdfcheckbox' + i + '" type="checkbox" value="' + Object.keys(e)[0] + '"><label for="pdfcheckbox' + i + '""><p>' + Object.values(e)[0] + '</p></label></div></div>');
            });
        }

        document.getElementById('downloadpdf').addEventListener("click", function () {
            var pdfPrintPrefix = 'http://productfinder.wilo.com/live_ww/server/scripts/base/pfinder.php?client=wilo&action=getPDF';
			if (typeof window.wiloconfig !== 'undefined' && typeof window.wiloconfig.pdfPrintPrefix !== 'undefined') {
				pdfPrintPrefix = window.wiloconfig.pdfPrintPrefix;
			}
			this.setAttribute('href', '');
            var generatePdfElement = document.querySelector('[data-pfinder-pdf-for]');
            this.setAttribute('href', pdfPrintPrefix                
                + '&lcc=' + generatePdfElement.getAttribute('data-pfinder-pdf-country')
                + '&lcd=' + generatePdfElement.getAttribute('data-pfinder-pdf-lang')
                + '&=1'
                + '&pid=' + generatePdfElement.getAttribute('data-pfinder-pdf-pid')
                + '&cat=' + generatePdfElement.getAttribute('data-pfinder-pdf-cat')
                + '&print_tabs=' + generatePdfTabs()
                + '&format=' + document.querySelector('.pdfformat:checked').value
                + '&user_company=' + document.getElementById('pdfcompany').value
                + '&user_name=' + document.getElementById('pdfname').value
                + '&user_street=' + document.getElementById('pdfstreet').value
                + '&user_zipcode=' + document.getElementById('pdfzip').value
                + '&user_town=' + document.getElementById('pdftown').value
                + '&user_phone=' + document.getElementById('pdftelephone').value
                + '&user_fax=' + document.getElementById('pdffax').value
                + '&user_email=' + document.getElementById('pdfemail').value
                + '&user_project=' + document.getElementById('pdfproject').value
                + '&user_installation-site=' + document.getElementById('pdfinstallation').value
            );
        });


        function generatePdfTabs() {
            console.log(1);
            var tabsString = '';
            if (document.querySelector('.pdfcheckbox')) {
                if (!document.querySelector('.pdfcheckbox:checked')) {
                    var elements = document.getElementsByClassName('pdfcheckbox');
                    tabsString += getValues(elements);
                } else {
                    var elements = document.querySelectorAll('.pdfcheckbox:checked');
                    tabsString += getValues(elements);
                }
                return tabsString.substring(0, tabsString.length - 1);
            }
        }

        function getValues(elements) {
            var values = '';
            for (var i = 0; i < elements.length; i++) {
                values += elements[i].value + ',';
            }
            return values;
        }

    }

}, false);

$('.toggle-optional').click(function() {
    $(this).toggleClass('active');
    $(this).siblings('.optional-content').slideToggle();
})