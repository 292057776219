export default function initBSRPumpReplacementTeaser() {
    const elements = document.querySelectorAll('.bsr-pump-replacement-teaser');
    elements.forEach((element) => {
        const redirectTemplate = element.dataset.redirectTemplate;
        const form = element.querySelector('.bsr-pump-replacement-teaser__form');

        form.onsubmit = (e) => {
            e.preventDefault();
            const formData = new FormData(form);
            const searchTerm = formData.get('searchTerm');
            const targetUrl = redirectTemplate.replace('{searchTerm}', searchTerm);
            window.location.href = targetUrl;
        }
    });
}