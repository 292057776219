/**
 * Array.isArray polyfill
 */
if (!Array.isArray) {
    Array.isArray = function (vArg) {
        return Object.prototype.toString.call(vArg) === '[object Array]';
    };
}
/**
 * MyWilo API
 */
(function () {
    var WiloWishlistApi = /** @class */ (function () {
        function WiloWishlistApi() {
            var _this = this;
            this._myWiloCountry = window['wiloconfig'].country.country;
            this._storageKey = window['wiloconfig'].angular.wishlist.storageKey + '/categories';
            this.localeId = window['wiloconfig'].country.locale;
            this._wishlistEntriesIndexed = {};
            this._wishlistEntries = [];
            this._wishListCountElement = document.getElementById('wishlist-counter');
            this._readCounter();
            this._getCurrentSaveState();
            window.addEventListener('message', function (event) {
                if (event.origin === window.location.origin) {
                    _this.getWishlistEntriesCount();
                }
            }, false);
        }
        /**
         * @private
         */
        WiloWishlistApi.prototype._getCurrentSaveState = function () {
            var _this = this;
            var saveState = (function () {
                try {
                    return JSON.parse(window.localStorage.getItem(_this._storageKey));
                }
                catch (_) {
                    return null;
                }
            })();
            if (saveState == null || !Array.isArray(saveState) || saveState.length === 0) {
                // we have no entries in the list, so we create the default Bookmarks category.
                this._createDefaultBookmarksCategory();
            }
            else {
                this._wishlistEntries = saveState;
                this._updateIndex();
            }
        };
        /**
         * @private
         */
        WiloWishlistApi.prototype._createDefaultBookmarksCategory = function () {
            this._wishlistEntries.push({
                id: 'bookmarks',
                categoryName: 'bookmarks',
                series: [],
                products: [],
                accessoires: [],
                services: [],
                notes: null
            });
            this._updateIndex();
            this._flushStore();
        };
        /**
         * @private
         */
        WiloWishlistApi.prototype._updateIndex = function () {
            var _this = this;
            this._wishlistEntriesIndexed = {};
            (this._wishlistEntries || []).forEach(function (category) {
                if (category.id != null) {
                    _this._wishlistEntriesIndexed[category.id] = category;
                }
            });
        };
        /**
         * @private
         */
        WiloWishlistApi.prototype._flushStore = function () {
            window.localStorage.setItem(this._storageKey, JSON.stringify(this._wishlistEntries));
        };
        WiloWishlistApi.prototype._storeInCategoryCollection = function (categoryId, collectionName, payload) {
            this._wishlistEntriesIndexed[categoryId][collectionName].push(payload);
            this._flushStore();
        };
        /**
         * Adds a new service to the given category.
         */
        WiloWishlistApi.prototype.addService = function (serviceName, url) {
            this._updateWishlist({ 'serviceName': serviceName, 'url': url }, 'services');
        };
        /**
         * @param {string} articleNumber
         * @param {number} listPrice list price in EUR
         */
        WiloWishlistApi.prototype.addProduct = function (productName, articleNumber, listPrice, url) {
            this._updateWishlist({
                'productName': productName,
                'articleNumber': articleNumber,
                'listPrice': listPrice,
                'url': url
            }, 'products');
        };
        /**
         * @param {string} itemName
         * @param {string} itemNumber
         * @param {number} listPrice list price in EUR
         * @param {string} url
         */
        WiloWishlistApi.prototype.addAccesoire = function (itemName, itemNumber, listPrice, url) {
            this._updateWishlist({
                'itemName': itemName,
                'itemNumber': itemNumber,
                'listPrice': listPrice,
                'url': url
            }, 'accessoires');
        };
        /**
         * @param {string} seriesName
         * @param {string} imageUrl
         * @param {string} url
         */
        WiloWishlistApi.prototype.addSeries = function (seriesName, imageUrl, url) {
            this._updateWishlist({ 'seriesName': seriesName, 'imageUrl': imageUrl, 'url': url }, 'series');
        };
        /**
         * Checks if the given entry exists in the wishlist.
         *
         * @param type The collection type to check.
         * @param url The url to check.
         */
        WiloWishlistApi.prototype.hasEntry = function (type, url) {
            return this._alreadyExists({ url: url }, type);
        };
        /**
         * Get all currently stored wishlist categories.
         *
         * @returns Array<{id: string, categoryName: string}> an array of objects with categoryName (string) and id (string) attributes
         */
        WiloWishlistApi.prototype.getCurrentCategories = function () {
            var cats = [];
            this._wishlistEntries.forEach(function (category) {
                cats.push({
                    id: category.id,
                    categoryName: category.categoryName
                });
            });
            return cats;
        };
        /**
         * Returns the current number of entries in the wish wish list.
         */
        WiloWishlistApi.prototype.getWishlistEntriesCount = function () {
            var totalCount = 0;
            if (this._wishListCountElement) {
                var startTextIndex = this._wishListCountElement.innerHTML.indexOf('(');
                this._wishlistEntries.forEach(function (category) {
                    totalCount += category.series != null ? category.series.length : 0;
                    totalCount += category.products != null ? category.products.length : 0;
                    totalCount += category.services != null ? category.services.length : 0;
                });
                if (this._wishListCountElement) {
                    this._wishListCountElement.innerHTML = this._wishListCountElement
                        .innerHTML.substring(0, startTextIndex) + totalCount;
                }
            }
        };
        WiloWishlistApi.prototype._getBaseUrl = function () {
            var baseUrl;
            if (window.location.origin !== 'http://localhost:4200') {
                baseUrl = window.location.origin + '/services';
            }
            else {
                baseUrl = 'http://localhost:4200';
            }
            return baseUrl;
        };
        WiloWishlistApi.prototype._getWindow = function () {
            return window;
        };
        /**
         * puts a new value to the 'Bookmarks' category and persists it if the user is logged in
         * saves the new value into the localStorage otherwise
         */
        WiloWishlistApi.prototype._updateWishlist = function (entry, collectionName) {
            if (!this._alreadyExists(entry, collectionName)) {
                this._storeInCategoryCollection('bookmarks', collectionName, entry);
                window.postMessage('counter-event', window.location.origin);
                this._setNotification(true);
            }
            else {
                this._setNotification(false);
            }
        };
        /**
         * finds the cookie with the given name
         * @param name
         */
        WiloWishlistApi.prototype._getCookie = function (name) {
            var value = '; ' + document.cookie;
            var parts = value.split('; ' + name + '=');
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        };
        /**
         * shows a notification when an entry was set to the default category
         */
        WiloWishlistApi.prototype._setNotification = function (success) {
            var offCanvasContainer = document.getElementById('offcanvas');
            var offCanvasAlert = document.getElementById('off-canvas-alert');
            var offCanvasLabel = document.getElementById('off-canvas-alert-content');
            if (!offCanvasContainer || !offCanvasAlert || !offCanvasLabel) {
                console.warn("can not set the notification due to missing dom elements");
                return;
            }
            offCanvasContainer.style.display = 'block';
            offCanvasAlert.style.display = 'block';
            if (success) {
                offCanvasLabel.innerText = this._getWindow().wiloconfig.MY_WILO_TRANSLATION_KEYS.wishlist.msgAddedToWishlist;
            }
            else {
                offCanvasLabel.innerText = this._getWindow().wiloconfig.MY_WILO_TRANSLATION_KEYS.wishlist.msgAddedToWishlistError;
            }
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': offCanvasContainer.offsetTop
            });
            setTimeout(function () {
                offCanvasLabel.innerText = '';
                offCanvasLabel.style.color = '#FFF';
                offCanvasContainer.style.display = 'none';
                offCanvasAlert.style.display = 'none';
            }, 5000);
        };
        WiloWishlistApi.prototype._readCounter = function () {
            var _this = this;
            window.onload = function () {
                _this.getWishlistEntriesCount();
            };
        };
        /**
         * Checks if the given already exists in the given collection.
         *
         * @param entry
         * @param collectionName
         * @return True if the entry is already in the given collection.
         * @private
         */
        WiloWishlistApi.prototype._alreadyExists = function (entry, collectionName) {
            var wishlistCategories = JSON.parse(localStorage.getItem(this._storageKey));
            if (wishlistCategories !== null && wishlistCategories !== undefined) {
                var collection = wishlistCategories[0][collectionName];
                if (!collection) {
                    console.warn("collection \"" + collectionName + "\" does not exist.");
                    return false;
                }
                for (var _i = 0, collection_1 = collection; _i < collection_1.length; _i++) {
                    var availableEntry = collection_1[_i];
                    if (availableEntry.url === entry.url) {
                        return true;
                    }
                }
            }
            return false;
        };
        return WiloWishlistApi;
    }());
    window.wiloWishListApi = new WiloWishlistApi();
})();
