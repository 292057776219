(function () {

    var languages = [];
    var currLang = document.querySelector('html').getAttribute('lang').toUpperCase();

    function checkLanguages(lang) {
        var duplicate = false;
        if(languages.length) {
            languages.forEach(function(language) {
                if(language.langName === lang) {
                    duplicate = true;
                }
            });
        }
        return duplicate;
    }

    document.querySelectorAll('head link').forEach(function (link) {
        var lang = link.getAttribute('hreflang');
        if (lang) {
            lang = {
                langName: lang.split('-')[0].toUpperCase(),
                link: link.getAttribute('href')
            };
            if (lang.langName !== currLang && !checkLanguages(lang.langName)) {
                languages.push(lang);
            }
        }
    });

    function generateLanguageElement(language) {
        return '<li> <a href="' + language.link + '">' + language.langName + '</a></li>';
    }

    if (languages.length) {
        document.querySelectorAll('.language-switcher').forEach(function (switcher) {
            var langList = switcher.querySelector('.language-list');
            langList.innerHTML = '';
            languages.forEach(function (language, index) {
                langList.insertAdjacentHTML('beforeend', generateLanguageElement(language));
            });
        });
    }

}());

$('.language-switcher').click(function () {
    $('#language_switcher .wilo-icon').toggleClass('rotateQuarter');
    $('.language-switcher .language-list').slideToggle('fast');
});

$(document).click(function (event) {
    if (!$(event.target).is('.current-language, #language_switcher, #language_switcher .wilo-icon')) {
        $('.language-switcher .language-list').slideUp();
        $('#language_switcher .wilo-icon').addClass('rotateQuarter');
    }
});