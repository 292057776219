var stageSlides = document.querySelectorAll(".stageSlide");
var stageButtons = document.querySelectorAll(".stageButton");
let sel = true;

var updateActive = function (index) {
  stageSlides.forEach((slide) => {
    slide.classList.remove("active");
  });

  stageButtons.forEach((button) => {
    button.classList.remove("active");
  });

  stageSlides[index].classList.add("active");
  stageButtons[index].classList.add("active");
};

stageButtons.forEach((button, i) => {
  button.addEventListener("click", () => {
    updateActive(i);
    sel = false;
  });
});

var repeat = function () {
  let i = 0;
  var repeater = () => {
    setTimeout(function () {
      if (sel) {
        i = (i + 1) % stageSlides.length;
        updateActive(i);

        repeater();
      }
    }, 12000);
  };
  repeater();
};

if(stageSlides.length > 0) {
  repeat();
}
