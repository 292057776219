import { scrollSmooth } from "./main";

document.querySelector('body').addEventListener('click', function (event) {
    if (event.target.classList.contains('step')) {
        var elem = event.target;
        var steps = $(elem).parent().find('.step');
        if (steps.index($('.step.current')) > steps.index($(elem))) {
            $(elem).parent().find('.step').removeClass('current').addClass('js-finished');
            $(elem).addClass('current').removeClass('js-finished');
            toggleStepContent($(elem));
            deactivateFollowingSteps($(elem));
            $(".progress-steps-content > div").hide();
            $($(elem).attr('data-content-selector')).show();
        }
    }
});

document.querySelector('body').addEventListener('click', function (event) {
    if (event.target.classList.contains('next')) {
        var next = $('.step.current').next();
        if (next.length > 0) {
            scrollSmooth(document.getElementsByClassName('form-progress')[0].offsetTop);
            $('.step.current').removeClass('current').addClass('js-finished').next().addClass('current');
            toggleStepContent(next);
        }
    }
});

document.querySelector('body').addEventListener('click', function (event) {
    if (event.target.classList.contains('prev')) {
        var prev = $('.step.current').prev();
        if (prev.length > 0) {
            scrollSmooth(document.getElementsByClassName('form-progress')[0].offsetTop);
            $('.step.current').removeClass('current').prev().removeClass('js-finished').addClass('current');
            toggleStepContent(prev);
        }
    }
});

function deactivateFollowingSteps(elem) {
    var next = elem.next();
    while (next.length > 0) {
        next.removeClass('js-finished');
        next = next.next();
    }
}

function toggleStepContent(current) {
    $('.progress-steps-content > div').hide();
    $(current.data('contentSelector')).show();
}

export function stepJump(form, tab) {
    var steps = form.find('.step');
    steps.removeClass('current');
    var currStep = $(steps[tab]).addClass('current');
    toggleStepContent(currStep);
    deactivateFollowingSteps(currStep);
}

// Load Tabs from Url for History Back
function urlTabMapping() {
    var currTabs = new URL(document.URL).searchParams.get('formStep');
    if (currTabs) {
        currTabs = JSON.parse(currTabs);
        Object.values(currTabs).forEach(function (e, i) {
            var steps = $($('.form-progress')[i]).find('.step');
            steps.removeClass('current');
            $(steps[parseInt(e)]).addClass('current');
        });
    }
    $($(".step.current").attr("data-content-selector")).show();
}
