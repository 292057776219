jQuery(function ($) {
    var countdownDate = $('.countdown-container').data('event');
    /* Datum */
    function getTimeRemaining(endtime) {
        var t = Date.parse(endtime) - Date.parse(new Date());
        var seconds = Math.floor(t / 1000 % 60);
        var minutes = Math.floor(t / 1000 / 60 % 60);
        var hours = Math.floor(t / (1000 * 60 * 60) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24) % 7);
        var weeks = Math.floor(t / (1000 * 60 * 60 * 24 * 7));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds,
            'weeks': weeks
        };
    }

    var $days = $('.countdown-container .countdown-days');
    var $hours = $('.countdown-container .countdown-hours');
    var $minutes = $('.countdown-container .countdown-minutes');
    var $seconds = $('.countdown-container .countdown-seconds');
    var countdownInterval;

    function initializeCounter() {
        var remaining = getTimeRemaining(countdownDate);
        if (remaining.total >= 0) {
            $hours.text(remaining.hours);
            $days.text(remaining.days + (remaining.weeks * 7));
            $seconds.text(remaining.seconds);
            $minutes.text(remaining.minutes);
        } else {
            $('.countdown-container').remove();
            if (countdownInterval) {
                clearInterval(countdownInterval);
            }
        }
    }
    initializeCounter();
    countdownInterval = setInterval(initializeCounter, 1000);
});